import React, { useEffect, useState } from "react";
import injectedModule, { ProviderLabel } from "@web3-onboard/injected-wallets";
import Onboard from "@web3-onboard/core";
import torusModule from "@web3-onboard/torus";
import walletConnectModule from "@web3-onboard/walletconnect";
import { AppParams, networks } from "../config";
import { useRecoilState } from "recoil";
import {
  accountState,
  collectionNameState,
  configurationState,
  isConnectingState,
  networkIdState,
  web3ApiState,
} from "../services/atoms";
import httpService from "../services/httpService";
import { ethers } from "ethers";
import { Button } from "react-bootstrap";
import SwitchDesign from "../components/SwitchDesign";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { onboard } from "../components/Header";
import { handleRegister } from "../services/authentication";
import Image from 'react-bootstrap/Image'

export default function HowItWork() {
  const [account, setAccount] = useRecoilState(accountState);
  const [web3Api, setWeb3Api] = useRecoilState(web3ApiState);
  const [isConnecting, setIsConnecting] = useRecoilState(isConnectingState);
  const [networkId, setNetworkId] = useRecoilState(networkIdState);
  const [error, setError] = useState();
  const [provider, setProvider] = useState();
  const { collection } = useParams();
  const { t } = useTranslation();

  const [collectionName, setCollectionName] = useRecoilState(collectionNameState)

  const changeNetwork = async ({ networkName, setError }) => {
    try {
      if (!web3Api.provider) throw new Error("No crypto wallet found");

      await web3Api.provider.provider.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            ...networks[networkName],
          },
        ],
      });
      setNetworkId(networkName);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if(collection == null || collection == "") return
    setCollectionName(collection)
  }, [collection])
  

  const handleNetworkSwitch = async (networkName) => {
    await changeNetwork({ networkName, setError });
  };
  const connectWallet = async () => {
    try {
      // if (!ethereum) {
      //   setIsMetamaskAlertOpened(true);
      //   return;
      //   //return alert('Please install metamask')
      // }

      let provider;
      // Loading...
      setIsConnecting(true);
      // Get all the accounts and the user will be able to choose one.
      let wallets;
      try {
        let wallet = window.localStorage.getItem("wallet");
        if (
          wallet !== undefined &&
          wallet !== "undefined" &&
          wallet !== null &&
          wallet !== "null"
        ) {
          wallets = await onboard.connectWallet({ autoSelect: wallet });
        } else {
          wallets = await onboard.connectWallet();
        }

        if (wallets[0]) {
          setWeb3Api((prevState) => {
            return { ...prevState, isLoading: true, success: false };
          });
          provider = new ethers.providers.Web3Provider(
            wallets[0].provider,
            "any"
          );
          setProvider(provider);
          setWeb3Api((prevState) => {
            return {
              ...prevState,
              isLoading: false,
              success: true,
              provider: provider,
            };
          });
          const accounts = await provider.provider.request({
            method: "eth_requestAccounts",
          });

          // debugger;
          if (
            (localStorage.getItem("loggedOut") == "true" ||
              localStorage.getItem("loggedOut") == null) &&
            localStorage.getItem("token") == null
          ) {
            const signature = await provider.provider.request({
              method: "personal_sign",
              params: [accounts[0], accounts[0]],
            });
            window.localStorage.setItem(
              "wallet",
              onboard.state.get().wallets[0].label
            );

            await handleRegister(localStorage.getItem("collection"),accounts[0], signature);
          }

          localStorage.setItem("loggedOut", "false");

          // Loading...
          setIsConnecting(false);
          setAccount(accounts[0]);
          // Test provider avalanche

          if (provider.provider.chainId !== AppParams.AppChainId) {
            await handleNetworkSwitch(AppParams.AppChainId);
            // setIsAvalanche(false)
          }

          window.localStorage.setItem(
            "wallet",
            onboard.state.get().wallets[0].label
          );

          provider &&
            provider.provider.on("accountsChanged", function (accounts) {
              setAccount(accounts[0]);
            });
        } else {
          setIsConnecting(false);
        }
      } catch (error) {
        console.log(error);

        setIsConnecting(false);
        return;
      }
    } catch (error) {
      console.log(error);
      setIsConnecting(false);
      throw new Error("No ethereum object.");
    }
  };
  const [configuration, setConfiguration] = useRecoilState(configurationState)

  useEffect(() => {
      if (collection == null) return;
      httpService.get(
          `${AppParams.BACKEND_URL}/config/projects?projectID=${collection}`
          ,
      )
          .then(response => {
              setConfiguration(response.data)
          })
          .catch(error => {
              console.log(error)
          });
  }, [collection])

  return (
    <>
      <SwitchDesign collectionName={collection} />
      <div className="positionW container">
      <Image src={configuration.logoUri} width="150" className="imgRap" />
      <h3>{t('Start O2S Martketplace NFT in seven easy steps')}</h3>
        <ol className="list-group vertical-steps workS">
          <li className="list-group-item">
            <span className="p-2">
              <b> {`${t("Verify that's you have a wallet")}`}:</b>
              <p className="m-0 p-0 d-flex justify-content-start align-items-center">
                <a
                  href="https://metamask.io/download/"
                  target="_blank"
                  rel="noreferrer"
                  className="d-inline-block mx-1"
                >
                  Metamask
                </a>
                or
                <a
                  href="https://app.tor.us/v1.41.1/"
                  target="_blank"
                  rel="noreferrer"
                  className="d-inline-block mx-1"
                >
                  Torus
                </a>
              </p>
            </span>
          </li>
          <li className="list-group-item ">
            <span className="d-flex justify-content-between align-items-center p-2">
              {" "}
              <b>{t("Connect your wallet")}</b>
              {account !=="" && (
                <Button
                  variant="primary"
                  className="btn-sm"
                  onClick={connectWallet}
                  disabled={isConnecting}
                >
                  {isConnecting
                    ? `${t("Connecting wallet")}...`
                    : t("Connect wallet")}
                </Button>
              )}
            </span>
          </li>
          <li className="list-group-item">
            <span className="d-flex justify-content-between align-items-center p-2">
              {" "}
              <b>
                {`${t("Configure your wallet on")} ${
                  AppParams.AppChainName
                } network`}
              </b>
              {account &&
                web3Api.provider &&
                web3Api.provider.provider &&
                AppParams.AppChainId != web3Api.provider.provider.chainId && (
                  <Button
                    variant="primary"
                    className="btn-sm"
                    onClick={() => {
                      handleNetworkSwitch(AppParams.AppChainId);
                    }}
                  >
                    {t("Change network")}
                  </Button>
                )}
            </span>
          </li>
          <li className="list-group-item">
            <span className="p-2">
              {" "}
              <b>{t("Buy Ether")}</b>
              <p>
                {t(
                  "If you're looking to purchase Ether and transfer it to your wallet, there are numerous options available to you. In this tutorial, we'll guide you through the process of buying Ether from providers that are recommended by popular wallets"
                )}
                <p>
                  <a
                    href="https://amir-louati.gitbook.io/o2b-marketplace/how-to-buy-an-nft#3.-check-your-account-balance"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Click Here for Tutorial")}
                  </a>
                </p>
              </p>
            </span>
          </li>
          <li className="list-group-item">
            <span className="p-2">
              {" "}
              <b>{t("Convert Ether to USDT")}</b>
              <p>
                {t(
                  "In order to purchase O2B, you'll need to convert your Ether to USDT. It's recommended to keep at least $5 worth of Ether in your wallet to cover any transaction fees that may be incurred"
                )}
              </p>
            </span>
          </li>
          <li className="list-group-item">
            <span className="p-2">
              {" "}
              <b>{t("Buy O2B")}</b>
              <p>
                {t("To purchase an NFT, you'll need to possess O2B tokens")}
              </p>
            </span>
          </li>
          <li className="list-group-item">
            <span className="p-2">
              {" "}
              <b>Buy NFT</b>
              <p>
                {t(
                  "Click on the 'buy' button for the desired NFT, and then confirm both transactions"
                )}
              </p>
            </span>
          </li>
        </ol>
      </div>
    </>
  );
}
