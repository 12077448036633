import { ethers } from "ethers";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { accountState, referralAddressState } from "../services/atoms";

export default function ReferralModal(props) {
  const [account, setAccount] = useRecoilState(accountState);
  const [inputAddress, setInputAddress] = useState("");
  const [showInvalidAddressMessage, setShowInvalidAddressMessage] =
    useState(false);
  const [showSameAddressError, setShowSameAddressError] = useState(false);
  const { t, i18n } = useTranslation();

  const storedReferralAddress =useRecoilValue(referralAddressState);

  const handleClose = () => {
    setInputAddress("");
    setShowInvalidAddressMessage(false);
    setShowSameAddressError(false);
  };

  const handleAddressChanged = (event) => {
    setInputAddress(event.target.value);
    if (event.target.value !== "") {
      setShowInvalidAddressMessage(!ethers.utils.isAddress(event.target.value));
      if (event.target.value.toUpperCase() === account.toUpperCase()) {
        setShowSameAddressError(true);
      } else {
        setShowSameAddressError(false);
      }
    } else {
      setShowSameAddressError(false);
    }
  };

  useEffect(() => {
    if(props.opened == true && inputAddress == ''){
      setInputAddress(storedReferralAddress)
    }
  }, [props.opened,storedReferralAddress])
  

  return (
    <>
      <Modal
        className="Imetamask pend"
        show={props.opened}
        onHide={(e) => {
          //setPurchaseErrorMessage('')
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-center align-items-center">
          <h3 className="text-center">{t("Referral program")}</h3>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <div>{t("Do you have a referral code?")}</div>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>{t("Use it here for other benefits")}</Form.Label>
            <input
              className="form-control"
              type="text"
              placeholder="Must be a valid ethereum address"
              value={inputAddress}
              onChange={handleAddressChanged}
            />
            {showInvalidAddressMessage ? (
              <div>{t("Invalid ethereum address")}</div>
            ) : null}
            {showSameAddressError ? (
              <div>
                {t("Address must not be the same used for the connection")}
              </div>
            ) : null}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              props.onCancel();
              handleClose();
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={(e) => {
              props.onIgnoreAndContinue();
              handleClose();
            }}
          >
            {t("Ignore and continue")}
          </Button>
          <Button
            disabled={
              showSameAddressError ||
              inputAddress === "" ||
              inputAddress === null ||
              showInvalidAddressMessage
            }
            onClick={(e) => {
              props.onUseReferralAndContinue(inputAddress);
              handleClose();
            }}
          >
            {t("Use referral and continue")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
