import { ethers } from 'ethers'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { DeployRouterAbi } from '../artifacts/contracts-abis/DeployRouter-abi'
import { MarketplaceAbi } from '../artifacts/contracts-abis/Marketplace-abi'
import { MinterNFTFactory } from '../artifacts/contracts-abis/MinterNFTFactory'
import EditCategories from '../components/EditCategories'
import { AppParams } from '../config'
import { accountState, web3ApiState } from '../services/atoms'

export default function DeployNFTContract() {


    const { t, i18n } = useTranslation();

    const [account, setAccount] = useRecoilState(accountState)
    const [web3Api, setWeb3Api] = useRecoilState(web3ApiState)

    const [name, setName] = useState('O2B_START_TEST');
    const [tokenName, setTokenName] = useState('NO2B');
    const [symbol, setSymbol] = useState('NO2B');
    const [baseURI, setBaseURI] = useState('ipfs://');
    const [collectionURI, setCollectionURI] = useState('QmVCRNPhzXA5DWi1sK3sSyfbpopp8vNLZUoGWcCfJrQZDp');
    const [owner, setOwner] = useState(null);
    const [paymentToken, setPaymentToken] = useState("0xd66b8E3685fBc8f98EdEC0810dD27Faeb51bb0F2");
    const [categories, setCategories] = useState(null);
    const [categoriesURIs, setCategoriesURIs] = useState(null);
    const [isMinterMarketplace, setIsMinterMarketplace] = useState(true);
    const [prices, setPrices] = useState(null);
    const [withWhitelist, setWithWhitelist] = useState(false);
    const [deploymentError, setDeploymentError] = useState(null)
    const [deploymentGasEstimation, setDeploymentGasEstimation] = useState(0);
    const [gasPrice, setGasPrice] = useState(0);
    //const [transactionFees, setTransactionFees] = useState(0);

    useEffect(() => {
        if (owner == null && account != '') {
            setOwner(account)
        }
    }, [account])

    const getNFTfactoryContract = async () => {
        console.log("handleDeploy")
        //const provider = new ethers.providers.Web3Provider(window.ethereum)
        //const provider = web3Api.provider
        const provider = new ethers.providers.Web3Provider(window.onBoardProvider, 'any')
        const signer = await provider.getSigner()
        getFeeData(provider);
        return Promise.resolve(new ethers.Contract(
            AppParams.DEPLOY_ROUTER_ADDRESS,
            DeployRouterAbi,
            signer
        )
        );
    }

    const handleDeploy = async () => {

        const nftFactoryContract = await getNFTfactoryContract();

        const params = {
            appName_: name,
            name_: tokenName,
            symbol_: symbol,
            baseURI_: baseURI,
            collectionURI_: collectionURI,
            owner_: owner,
            nftMarketPlace_: AppParams.MARKET_ADDRESS,
            categories: categories,
            prices: prices,
            withWhitelist: withWhitelist
        }

        nftFactoryContract.deployNFT(params).then(transaction => {
            console.log("transaction", transaction);
        })

    }

    const handleEstimateDeploy = async () => {

        const nftFactoryContract = await getNFTfactoryContract();

        const params = {
            appName_: name,
            name_: tokenName,
            symbol_: symbol,
            baseURI_: baseURI,
            collectionURI_: collectionURI,
            owner_: owner,
            nftMarketPlace_: AppParams.MARKET_ADDRESS,
            categories: categories,
            prices: prices,
            withWhitelist: withWhitelist
        }


        nftFactoryContract.estimateGas.deployNFT(params).then(transaction => {
            //console.log("transaction estimation", ethers.utils.formatEther(transaction));
            console.log("transaction estimation", transaction);
            setDeploymentGasEstimation(transaction.toNumber())
            setDeploymentError(null)
        }).catch((error) => {
            let err = JSON.stringify(error)
            const jsonStartIndex = err.indexOf('{');
            const jsonEndIndex = err.lastIndexOf('}') + 1;
            const jsonString = err.slice(jsonStartIndex, jsonEndIndex);

            const jsonObject = JSON.parse(jsonString);
            console.log(jsonObject);
            setDeploymentError(jsonObject.reason)
        })

    }


    const getNFTMinterfactoryContract = async () => {
        console.log("handleDeployMinterContract")
        const provider = new ethers.providers.Web3Provider(window.onBoardProvider, 'any')
        const signer = provider.getSigner()

        getFeeData(provider);
        return Promise.resolve(new ethers.Contract(
            AppParams.DEPLOY_ROUTER_ADDRESS,
            DeployRouterAbi,
            signer)
        );

    }

    const handleDeployMinterContract = async () => {
        const nftFactoryContract = await getNFTMinterfactoryContract();

        const params = {
            appName_: name,
            name_: tokenName,
            symbol_: symbol,
            baseURI_: baseURI,
            collectionURI_: collectionURI,
            owner_: owner,
            nftMarketPlace_: AppParams.MINTER_MARKET_ADDRESS,
            paymentToken: paymentToken,
            categories: categories,
            //["Qmar48Ez8GkpUirtJLii9iVaXk5yH6dQveXCnYc5QDFXP5","QmcxtkyVQuC14fw75noRRChEgPoVZiNHNbuyn1JLV27SDV"],
            categoriesURIs: categoriesURIs,
            prices: prices,
            withWhitelist: withWhitelist
        }


        /*string appName_;
        string name_;
        string symbol_;
        string baseURI_;
        string collectionURI_;
        address owner_;
        address nftMarketPlace_;
        address paymentToken;
        string[] categories;
        string[] categoriesURIs;
        uint256[] prices;
        bool withWhitelist;*/

        nftFactoryContract.deployNFTMinter(
            params
        ).then(transaction => {
            console.log("transaction", transaction);
        })
    }

    const handleEstimateDeployMinterContract = async () => {
        const nftFactoryContract = await getNFTMinterfactoryContract();

        const params = {
            appName_: name,
            name_: tokenName,
            symbol_: symbol,
            baseURI_: baseURI,
            collectionURI_: collectionURI,
            owner_: owner,
            nftMarketPlace_: AppParams.MINTER_MARKET_ADDRESS,
            paymentToken: paymentToken,
            categories: categories,
            //["Qmar48Ez8GkpUirtJLii9iVaXk5yH6dQveXCnYc5QDFXP5","QmcxtkyVQuC14fw75noRRChEgPoVZiNHNbuyn1JLV27SDV"],
            categoriesURIs: categoriesURIs,
            prices: prices,
            withWhitelist: withWhitelist
        }

        nftFactoryContract.estimateGas.deployNFTMinter(
            params
        ).then(transaction => {
            //console.log("transaction estimation", ethers.utils.formatEther(transaction));
            console.log("transaction estimation", transaction);
            setDeploymentGasEstimation(transaction.toNumber())
            setDeploymentError(null)
        }).catch((error) => {

            let err = JSON.stringify(error)
            const jsonStartIndex = err.indexOf('{');
            const jsonEndIndex = err.lastIndexOf('}') + 1;
            const jsonString = err.slice(jsonStartIndex, jsonEndIndex);

            const jsonObject = JSON.parse(jsonString);
            console.log(jsonObject);
            setDeploymentError(jsonObject.reason)
        })
    }

    const getFeeData = async (provider) => {
        let feeData = await provider.getFeeData();
        console.log(feeData);
        setGasPrice(feeData.gasPrice.toNumber());
    }

    useEffect(() => {
        if (isMinterMarketplace) {
            handleEstimateDeployMinterContract()
        } else {
            handleEstimateDeploy();
        }

    }, [name,
        symbol,
        baseURI,
        collectionURI,
        owner,
        paymentToken,
        categories,
        categoriesURIs,
        prices,
        withWhitelist,
        isMinterMarketplace
    ])



    const categoriesChanged = (data) => {
        //console.log(data.categoriesURI.map(el => baseURI + el))
        setCategories(data.categories);
        setPrices(data.prices);
        //setCategoriesURIs(data.categoriesURI.map(el => baseURI + el));
        setCategoriesURIs(data.categoriesURI);
    }

    const handleMintOnSaleChanged = (e) => {
        e.persist();
        console.log(e.target.value);
        var value = (e.target.value === 'true');
        setIsMinterMarketplace(value)
    };
    const handleWithWhitelistChanged = (e) => {
        e.persist();
        console.log(e.target.value);
        var value = (e.target.value === 'true');
        setWithWhitelist(value)
    };

    return (
        <div style={{ display: "flex" }}>
            <div >
                <div style={{ display: "grid" }} onSubmit={handleDeploy}>
                    <label htmlFor="name">Name:</label>
                    <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />

                    <label htmlFor="symbol">Symbol:</label>
                    <input type="text" id="symbol" value={symbol} onChange={(e) => setSymbol(e.target.value)} />

                    <label htmlFor="symbol">Token name:</label>
                    <input type="text" id="tokenName" value={tokenName} onChange={(e) => setTokenName(e.target.value)} />

                    <label htmlFor="base-uri">Base URI:</label>
                    <input type="text" id="base-uri" value={baseURI} onChange={(e) => setBaseURI(e.target.value)} />

                    <label htmlFor="collection-uri">Collection CID:</label>
                    <input type="text" id="collection-uri" value={collectionURI} onChange={(e) => setCollectionURI(e.target.value)} />

                    <label htmlFor="owner">Owner:</label>
                    <input type="text" id="owner" value={owner} onChange={(e) => setOwner(e.target.value)} />

                    <label htmlFor="owner">Payment token:</label>
                    <input type="text" id="owner" value={paymentToken} onChange={(e) => setPaymentToken(e.target.value)} />

                    <label htmlFor="owner">KYC:</label>
                    <Form>
                        <div key={`inline-radio`} className="mb-3">
                            <Form.Check
                                inline
                                label="Enabled"
                                name="groupwithWhitelist"
                                type="radio"
                                value={true}
                                onChange={handleWithWhitelistChanged}
                                checked={withWhitelist === true}
                                id={`inline-radio-1`}
                            />
                            <Form.Check
                                inline
                                label="Disabled"
                                name="groupwithWhitelist"
                                type="radio"
                                value={false}
                                onChange={handleWithWhitelistChanged}
                                checked={withWhitelist === false}
                                id={`inline-radio-2`}
                            />
                        </div>
                    </Form>

                    <label htmlFor="owner">Mint mode:</label>
                    <Form>

                        <div key={`inline-radio`} className="mb-3">
                            <Form.Check
                                inline
                                label="Manual Mint"
                                name="group1"
                                type="radio"
                                value={false}
                                onChange={handleMintOnSaleChanged}
                                checked={isMinterMarketplace === false}
                                id={`inline-radio-1`}
                            />
                            <Form.Check
                                inline
                                label="Automatic mint on sale"
                                name="group1"
                                type="radio"
                                value={true}
                                onChange={handleMintOnSaleChanged}
                                checked={isMinterMarketplace === true}
                                id={`inline-radio-2`}
                            />
                        </div>
                    </Form>

                </div>
                <EditCategories withCalegoriesURIs={isMinterMarketplace} onValuesChanged={(data) => { categoriesChanged(data) }}></EditCategories>
                <Button disabled={deploymentError != null} onClick={() => { isMinterMarketplace ? handleDeployMinterContract() : handleDeploy() }}>{t("Deploy")}</Button>
            </div>
            <div>
                {
                    deploymentError != null ?
                        <Alert key="danger" variant="danger">
                            {deploymentError}
                        </Alert> :
                        <div>
                            <div style={{ display: "grid", marginLeft: "20px" }}>
                                <label htmlFor="gasLimit">Estimated gas limit:</label>
                                <input type="number" id="gasLimit" value={deploymentGasEstimation} />

                                <label htmlFor="gasPrice">Estimated gas Price:</label>
                                <input type="number" id="gasPrice" value={gasPrice} />
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}
