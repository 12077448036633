import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function ReferralsParamAdmin({ contractAddress, marketplaceContract }) {
    const { t, i18n } = useTranslation();
    const [referralMaxLevels, setReferralMaxLevels] = useState(0);
    const [rewardRates, setRewardRates] = useState([]);
    const [rewardRatesVersion, setRewardRatesVersion] = useState(0);



    useEffect(async () => {

        const contract = marketplaceContract;

        let REFERRALS_MAX_LEVELS = await contract.REFERRALS_MAX_LEVELS(contractAddress);
        console.log("REFERRALS_MAX_LEVELS", REFERRALS_MAX_LEVELS)
        setReferralMaxLevels(REFERRALS_MAX_LEVELS)

    }, [contractAddress])




    const updateReferralsMaxLevel = async () => {

        const contract = marketplaceContract;

        contract.updateReferralsMaxLevel(contractAddress, referralMaxLevels).then(tx => {
            console.log(tx)
        }).catch(err => {
            console.error(err);
        })
    }





    useEffect(() => {
        getReferralLevelReward()
    }, [referralMaxLevels])

    useEffect(() => {
        if (rewardRatesVersion == 0) return;
        getReferralLevelReward()
    }, [rewardRatesVersion])


    const getReferralLevelReward = async () => {
        const contract = marketplaceContract;

        let elements = []
        for (let index = 0; index < referralMaxLevels; index++) {
            const levelreward = await contract.referralLevelReward(contractAddress, index)
            elements.push({
                index: index,
                value: levelreward.toNumber() / (10 ** 6)
            });
        }
        setRewardRates(elements);
    }

    const updateLevelRewardRate = async (level) => {

        const contract = marketplaceContract;

        let tx = await contract.updateLevelRewardRate(contractAddress, level, rewardRates[level].value * (10 ** 6));
        console.log('updateLevelRewardRate tx', tx)
        let txResult = await tx.wait();
        console.log('updateLevelRewardRate txResult', txResult)
        setRewardRatesVersion(rewardRatesVersion + 1)
    }

    const handleChange = (index,value) => {
        const newData = [...rewardRates];
        newData[index] = { index: index, value:value };
        setRewardRates(newData)
    }


    const getReferralsRewards = () => {
        let elements = []
        for (let index = 0; index < rewardRates.length; index++) {
            elements.push(
                <div>
                    <span>Level {index}</span>
                    <input type="text" id={`reward-level-${index}`} value={rewardRates[index].value} onChange={(e) => (handleChange(index,e.target.value))} />
                    <Button onClick={() => { updateLevelRewardRate(index) }}>{t("Update")}</Button>
                </div>
            )
        }
        return elements;
    }

    return (
        <div style={{ display: "flex" }}>
            <div >
                <div style={{ display: "grid" }}>
                    <label htmlFor="maxLevels">Max referrals link level:</label>
                    <input type="number" id="maxLevels" value={referralMaxLevels} onChange={(e) => setReferralMaxLevels(e.target.value)} />
                </div>
                <Button onClick={updateReferralsMaxLevel}>{t("Update")}</Button>
                {getReferralsRewards()}
            </div>
        </div>

    )
}
