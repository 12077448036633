import React from 'react'
import ReferralsList from '../components/Referrals/ReferralsList'
import { accountState, collectionNameState } from '../services/atoms'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useState } from 'react'
import { collectionDetails } from '../services/graphql'
import { TokensClient } from '../config'
import SwitchDesign from '../components/SwitchDesign'
import ReferralLink from '../components/Referrals/ReferralLink'
import ReferralRewards from '../components/Referrals/ReferralRewards'

export default function UserProfile() {

  const { collectionParam } = useParams()

  const [collectionName, setCollectionName] = useRecoilState(collectionNameState)

  const [account, setAccount] = useRecoilState(accountState)

  const [totalCollectedReferralreward, setTotalCollectedReferralreward] = useState(0)

  const [collectionInfos, setCollectionInfos] = useState({
    isMinterMarketplace: null,
    collectionAddress:null
  })


  useEffect(() => {
    if (collectionParam == null) return;
    localStorage.setItem("collection", collectionParam)
    setCollectionName(collectionParam)

    TokensClient.query({
      query: collectionDetails,
      fetchPolicy: 'network-only',
      variables: {
        collection: collectionParam,
      },
    }).then((res) => {
      let metas = {
        isMinterMarketplace: res.data.tokenContracts[0].isMinterMarketplace,
        collectionAddress: res.data.tokenContracts[0].id,
        whitelist: res.data.tokenContracts[0].whitelist,
        paymentToken: res.data.tokenContracts[0].paymentToken,
        paymentTokenName: res.data.tokenContracts[0].paymentTokenName,
        paymentTokenSymbol: res.data.tokenContracts[0].paymentTokenSymbol,
      }
      setCollectionInfos(metas)
    })
  
  }, [collectionParam])



  return (
    <div>
      <SwitchDesign collectionName={collectionParam} ></SwitchDesign>
      <div style={{display:"flex"}}>
        <ReferralLink className='col-sm-12 col-md-6' collectionName={collectionName}></ReferralLink>
        <ReferralRewards className='col-sm-12 col-md-6' totalCollectedReferralreward={totalCollectedReferralreward} paymentTokenSymbol={collectionInfos.paymentTokenSymbol}></ReferralRewards>
      </div>

      <ReferralsList account={account} collectionAddress={collectionInfos.collectionAddress} totalCollectedReferralrewardChanged={(data)=>{setTotalCollectedReferralreward(data)}} paymentTokenSymbol={collectionInfos.paymentTokenSymbol}></ReferralsList>
    </div>
  )
}
