import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ethers } from 'ethers'
import { create as ipfsHttpClient } from 'ipfs-http-client'




import {
    Button,
    Form
} from 'react-bootstrap'
import { MainTokenAbi } from '../artifacts/contracts-abis/MainToken-abi'
import GenerateNFTsMetas from '../components/GenerateNFTsMetas'
import { AppParams, MarketPlaceClient } from '../config'
import { useTranslation } from 'react-i18next'
import { tokenCategoriesList, tokenCategoriesListById } from '../services/graphql'

const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0')

const MintTokens = () => {
    const navigate = useNavigate()
    const { contractAddress } = useParams();
    const [formInput, updateFormInput] = useState({
        //main seller: '0x50a5b931c6E1B824C5d18791524B8FdF63aE894f',
        //test seller: 0xE6585C6103f0972F408529b9C1452Ac0751b5699,
        seller: '0x4b7b08F11d201e9dcE30B5Be7AC56D312A398E73',
        tokenId: '',
        name: '',
        description: '',
        mintFrom: 0,
        mintTo: 0,
        tokensNumberToMint: 3,
    })



    const [images, setImages] = useState([]);
    const [tokenInfos, setTokenInfos] = useState([]);
    const [classes, setClasses] = useState([]);
    //const [contractAddress, setContractAddress] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);

    const { t, i18n } = useTranslation();


    const fetchClasses = () => {
        MarketPlaceClient.query({
            query: tokenCategoriesListById,
            fetchPolicy: "network-only",
            variables: {
                collection: contractAddress
            }
        }).then((res) => {
            //setContractAddress(res.data.tokenClasses[0].contract.id)
            setClasses(res.data.tokenClasses);
        })
    }

    useEffect(() => {
        fetchClasses();
    }, [contractAddress])

    useEffect(() => {
    }, [classes])



    useEffect(() => {
        if (formInput.tokensNumberToMint == null || formInput.mintFrom == null) return;
        let toValue = (parseInt(formInput.mintFrom) + parseInt(formInput.tokensNumberToMint)) - 1
        updateFormInput({ ...formInput, mintTo: toValue })
    }, [formInput.tokensNumberToMint, formInput.mintFrom])


    async function uploadProcessedFiles(e) {


        const files = e.target.files


        for (var i = 0; i < files.length; i++) {
            var reader = new FileReader();
            reader.onload = onReaderLoad;
            reader.readAsText(files[i])
        }

        //reader.readAsText(files[0]);

        function onReaderLoad(event) {
            var obj = JSON.parse(event.target.result);
            var temp = images;
            temp = temp.concat(obj.Links)
            console.log("metas content", temp);
            //console.log("images contentccc", temp[0].Hash['/']);
            let tokenUris = [];
            for (var i = 0; i < temp.length; i++) {

                const data = JSON.stringify(temp[i])
                console.log("stringify data", data)
                tokenUris.push({
                    tokenId: temp[i].Name.split(".")[0],
                    uri: temp[i].Hash['/']
                }
                )
            }

            console.log("final token uris", tokenUris)
            setTokenInfos(tokenUris)
        }
    }


    async function handleCreateSale(e) {
        console.log("aaaa1")
        e.preventDefault();
        //const web3Modal = new Web3Modal()
        console.log("aaaa2")
        //const connection = await web3Modal.connect()
        console.log("aaaa3")
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        console.log("aaaa4")
        const signer = provider.getSigner()
        console.log("aaaa5")
        /* next, create the item */
        let contract = new ethers.Contract(contractAddress, MainTokenAbi, signer)

        let tokenIds = [];
        let tokenUris = [];
        let sellers = [];
        let categories = [];

        function compare(a, b) {
            if (parseFloat(a.tokenId) < parseFloat(b.tokenId)) {
                return -1;
            }
            if (parseFloat(a.tokenId) > parseFloat(b.tokenId)) {
                return 1;
            }
            return 0;
        }

        tokenInfos.sort(compare);

        const jumpIds = 0;
        for (var i = 0; i < (tokenInfos.length); i++) {
            tokenInfos[i].tokenId = parseInt(tokenInfos[i].tokenId)
            if (tokenInfos[i].tokenId >= formInput.mintFrom && tokenInfos[i].tokenId <= formInput.mintTo) {
                tokenIds.push(parseInt(tokenInfos[i].tokenId) + jumpIds)
                tokenUris.push(tokenInfos[i].uri)
                categories.push(selectedClass)
            }
        }
        console.log("tokenIds", tokenIds)
        console.log("tokensInfos", tokenInfos)

        const chunkSize = 10;
        for (let i = 0; i < tokenInfos.length; i += chunkSize) {
            const tokenIdsChunk = tokenIds.slice(i, i + chunkSize);
            const tokenUrisChunk = tokenUris.slice(i, i + chunkSize);
            const categoriesChunk = categories.slice(i, i + chunkSize);
            if (tokenIdsChunk.length > 0) {
                let transaction = await contract.batchMintAndSell(formInput.seller, tokenIdsChunk, categoriesChunk, tokenUrisChunk)
                let tx = await transaction.wait()
            }
        }
    }

    return (
        <div>
            <Form onSubmit={handleCreateSale}>
                <div>
                    {/*<GenerateNFTsMetas></GenerateNFTsMetas>*/}
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>{t("Collection address")}</Form.Label>
                        <Form.Control
                            type='text'
                            className='form-control'
                            placeholder={t('Collection address')}
                            aria-label='CollectionAddress'
                            value={contractAddress}
                            readOnly
                        /*onChange={(e) =>
                            updateFormInput({ ...formInput, collectionAddress: e.target.value })
                        }*/
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>{t("Seller")}</Form.Label>
                        <Form.Control
                            type='text'
                            className='form-control'
                            placeholder={t('Seller address')}
                            aria-label='SellerAddress'
                            value={formInput.seller}
                            onChange={(e) =>
                                updateFormInput({ ...formInput, seller: e.target.value })
                            }
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>{t("Number tokens to mint")}</Form.Label>
                        <Form.Control
                            type='number'
                            className='form-control'
                            placeholder={t('Number tokens to mint')}
                            aria-label='Number tokens to mint'
                            value={formInput.tokensNumberToMint}
                            onChange={(e) =>
                                updateFormInput({ ...formInput, tokensNumberToMint: e.target.value })
                            }
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>{t("Start mint from")}</Form.Label>
                        <Form.Control
                            type='number'
                            className='form-control'
                            placeholder={t('Start mint from')}
                            aria-label='Start mint from'
                            value={formInput.mintFrom}
                            onChange={(e) =>
                                updateFormInput({ ...formInput, mintFrom: e.target.value })
                            }
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>{t("Start mint to")}</Form.Label>
                        <Form.Control
                            type='number'
                            className='form-control'
                            placeholder={t('Start mint to')}
                            aria-label='Start mint to'
                            value={formInput.mintTo}
                            onChange={(e) =>
                                updateFormInput({ ...formInput, mintTo: e.target.value })
                            }
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                        <Form.Label>{t("Upload Prececced Metas")}</Form.Label>
                        <input
                            type='file'
                            name='Asset'
                            className='my-4'
                            accept=".json"
                            onChange={uploadProcessedFiles}
                        />
                    </Form.Group>
                </div>

                <div key={`inline-radio`} className="mb-3">
                    {
                        classes.map(element => {
                            return <Form.Check key={`inline-radio--${element.name}`}
                                inline
                                label={element.name}
                                name="groupSelectedClass"
                                type="radio"
                                value={selectedClass}
                                onChange={()=>{setSelectedClass(element.name)}}
                                checked={selectedClass === element.name}
                                id={`inline-radio-${element.name}`}
                            />
                        })
                    }

                </div>

                <Button type='submit'>MINT</Button>
            </Form>
        </div>
    )
}

export default MintTokens
