import React, { useEffect } from 'react'

 
import { useRecoilState, useRecoilValue } from 'recoil';
import { AppParams } from '../config';
import { collectionNameState, loggedInUserState } from '../services/atoms';
import httpService from '../services/httpService';

export default function UserInfos({account,onUserInfoFetched = null,onUserInfoError=null}) {

    const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserState)
    const collectionName = useRecoilValue(collectionNameState)

    useEffect(() => {
        httpService.get(
            `${AppParams.BACKEND_URL}/users/user-infos`
            ,
        )
        .then(response => {
            //console.log("loggedInUser?.websocketId user-infos",response.data)
            setLoggedInUser(response.data)

            if(onUserInfoFetched != null){
                onUserInfoFetched(response.data)
            }
            // if(response?.data.langue != null){
            //     if(i18n.language != response.data.langue.split("-")[0]){
            //         i18n.changeLanguage(response.data.langue.split("-")[0]);
            //     }
            // }

        })
        .catch(error => {
            console.log("UserInfos",error)
            //setLoggedInUser(null)
            if(onUserInfoError != null){
                onUserInfoError(error)
            }
        });
    }, [account])

    return (
        <div></div>
    )
}
