import Image from 'react-bootstrap/Image'
import React from 'react'
import { AppParams } from '../config';
import { useEffect } from 'react';
import httpService from '../services/httpService';
import { useRecoilState } from 'recoil';
import { configurationState } from '../services/atoms';
import { BsTelegram, BsLinkedin, BsInstagram, BsTwitter, BsFacebook } from "react-icons/bs";
import { ImTumblr2 } from "react-icons/im";
import { Link } from 'react-router-dom';

export default function CollectionInfos({ collection }) {

    const [configuration, setConfiguration] = useRecoilState(configurationState)

    useEffect(() => {
        if (collection == null) return;
        httpService.get(
            `${AppParams.BACKEND_URL}/config/projects?projectID=${collection}`
            ,
        )
            .then(response => {
                setConfiguration(response.data)
            })
            .catch(error => {
                console.log(error)
            });
    }, [collection])

    return (
        <div>
            <div className="ban">

                <div className="banierC" style={{ background: "url('" + configuration.bannerUri + "')" }}>
                    <div className="sBloc">
                        <h1>{configuration.name}</h1>
                        <h6>{configuration.projectTitle}</h6>
                    </div>
                </div>
                <div className="blocBo">
                    <Image src={configuration.logoUri} width="180" className="p-3 imgb" />
                    <div className="reS">
                        {configuration.telegramUrl !== null &&
                            <Link to={configuration.telegramUrl} target="_blank">
                                <BsTelegram />
                            </Link>
                        }
                        {configuration.linkedinUrl !== null &&
                            <Link to={configuration.linkedinUrl} target="_blank">
                                <BsLinkedin />
                            </Link>
                        }
                        {configuration.instagramUrl !== null &&
                            <Link to={configuration.instagramUrl} target="_blank">
                                <BsInstagram />
                            </Link>
                        }
                        {configuration.twitterUrl !== null &&
                            <Link to={configuration.twitterUrl} target="_blank">
                                <BsTwitter />
                            </Link>
                        }
                        {configuration.facebookUrl !== null &&
                            <Link to={configuration.facebookUrl} target="_blank">
                                <BsFacebook />
                            </Link>
                        }
                        {configuration.tumblrUrl !== null &&
                            <Link to={configuration.tumblrUrl} target="_blank">
                                <ImTumblr2 />
                            </Link>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}
