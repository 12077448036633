/*


Marketplace MINTER contract:  0xD1a3Ed250667211dC5D99DCB0400d3B39307c916
Marketplace MINTER proxy contract:  0xec345F77C5e9908194aF838913d6cfaB7a5dcc60
NFT FACTORY MINTER impl contract:  0x711d4CF457D7F48f648D86fbfd0A31cFE6800d64
NFT FACTORY MINTER proxy contract:  0x965c8010263c34aEb43c2B1420248a59e258EcC7
Marketplace contract:  0x1765869830d690Abee4Da38661f349093967c539
Marketplace proxy contract:  0xeBd7806E64a8D0C28A1180f483bD5C4194b8aB87
NFT FACTORY impl contract:  0xe5A77e18bfa6E71E5523cE40D1279e6aA66b7052
NFT FACTORY proxy contract:  0x6E583eF595cf76ae6f92e8D47b59F365139de9FB
routerInstAddress:  0x91F73218D25D3D6A9Ec746a39987592D5a1087c8
routerProxyAddress:  0x6D90b468D9fAfA2e34146CCda2016dfD3b27617c
******************************



*/


export const AppParams_dev = {
    DEPLOY_ROUTER_ADDRESS: '0x6D90b468D9fAfA2e34146CCda2016dfD3b27617c',
    MARKET_ADDRESS: '0xeBd7806E64a8D0C28A1180f483bD5C4194b8aB87',
    NFT_FACTORY_ADDRESS: '0x6E583eF595cf76ae6f92e8D47b59F365139de9FB',
    MINTER_MARKET_ADDRESS: '0xec345F77C5e9908194aF838913d6cfaB7a5dcc60',
    MINTER_NFT_FACTORY_ADDRESS: '0x965c8010263c34aEb43c2B1420248a59e258EcC7',
    AppChainId: '0x5',
    AppChainName:"Goerli",
    DefaultCollection:"O2B_START_TEST",
    DefaultCollectionAddress:"0xcc5bc62ecb2edbfddc47ce8a8d59b86d97e9516c",
    DynamicTokenClass:false,
    ActivateReferralModule:true,
    PAYEMENT_TOKEN_NAME: "USDT",
    ASSET_TOKEN_CONTRACT_ADDRESS: '0x5F5a58850f2b92FC1D14603245ab63E301D46704',
    FEES_REDUCER_TOKEN_CONTRACT_ADDRESS: "0x023be12ee8B09C3c15D15c89DE66F24890803ca5",
    PAYEMENT_TOKEN_CONTRACT_ADDRESS: '0xd66b8E3685fBc8f98EdEC0810dD27Faeb51bb0F2',
    STAKING_CONTRACT_ADDRESS: '0xf58AaCC929ECe27410400a882748DE97d8a1968e',
    WALLET_HOLDERS_CONTRACT_ADDRESS: '0x37f332915a14E5f0796B9c3aaa19cDf140f41262',
    RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc",
    BLOCK_EXPLORER_URL: 'https://goerli.etherscan.io/',
    NFT_MARKET_GRAPH: "https://api.thegraph.com/subgraphs/name/o2b-nft-marketplace/nfts-goerli-factory",
    NFT_MINTER_MARKET_GRAPH: "https://api.thegraph.com/subgraphs/name/o2b-nft-marketplace/nfts-goerli-factory",
    STAKING_GRAPH: "https://api.thegraph.com/subgraphs/name/ant-nft-subgraph/ant-staking-dev",
    TOKEN_GRAPH: "https://api.thegraph.com/subgraphs/name/o2b-nft-marketplace/nfts-goerli-factory",
    IPFS_GATWAY: 'https://ant-finance-dev.mypinata.cloud/ipfs/',
    BACKEND_URL:'https://api-nft.o2bprcl.xyz',
    WS_BACKEND : "wss://api-nft.o2bprcl.xyz/register-websocket",
    BLOCKPASS_ID : 'mygenioosblockpass_d0cb7',
    BYPASS_KYC : true
}


