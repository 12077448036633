import React, { useState, useEffect, useCallback } from "react";
import {
  Navbar,
  Container,
  Nav,
  Button,
  DropdownButton,
  Dropdown,
  Modal,
} from "react-bootstrap";
import axios from "axios";

import { LinkContainer } from "react-router-bootstrap";

import { shortenAddress } from "../utils/shortenAddress";

import { ethers } from "ethers";
import { AppParams, networks } from "../config";

import { useRecoilState, useSetRecoilState } from "recoil";
import {
  accountState,
  avaxValueUsd,
  collectionNameState,
  isConnectingState,
  isFactoryOwnerState,
  loggedInUserState,
  networkIdState,
  referralAddressState,
  referralReductionState,
  web3ApiState,
  whitelistActivatedState,
} from "../services/atoms";
import NotificationBar from "./NotificationBar";
import { Link, useNavigate } from "react-router-dom";
import httpService from "../services/httpService";
import KycForm from "./KycForm";
import UserInfos from "./UserInfos";
import { kycStatusVersionState } from "./../services/wsAtoms";
import WebsocketClientComponent from "./../utils/ws/WebsocketClientComponent";
import { useTranslation } from "react-i18next";
import LangSelector from "./LangSelector";
import { MarketplaceAbi } from "../artifacts/contracts-abis/Marketplace-abi";
import { NFTFactoryAbi } from "../artifacts/contracts-abis/NFTFactory-abi";
import SwitchDesign from "./SwitchDesign";
import Onboard from "@web3-onboard/core";
import injectedModule, { ProviderLabel } from "@web3-onboard/injected-wallets";
import torusModule from "@web3-onboard/torus";
import walletConnectModule from "@web3-onboard/walletconnect";
import { handleRegister } from "../services/authentication";
const torus = torusModule();
const { ethereum } = window;
//TODO add RPC URL to secret file and add the domain to infura allowlist
const MAINNET_RPC_URL =
  "https://goerli.infura.io/v3/083268db1d764794807c32c219a17cd6";

export const injected = injectedModule({
  filter: {
    [ProviderLabel.Binance]: false,
    [ProviderLabel.Coinbase]: false,
    [ProviderLabel.Phantom]: false,
    [ProviderLabel.SafePal]: false,
    [ProviderLabel.Zerion]: false,
    [ProviderLabel.OKXWallet]: false,
    [ProviderLabel.Tally]: false,
    [ProviderLabel.Opera]: false,
    [ProviderLabel.Status]: false,
    [ProviderLabel.AlphaWallet]: false,
    [ProviderLabel.AToken]: false,
    [ProviderLabel.Bitpie]: false,
    [ProviderLabel.BlockWallet]: false,
    [ProviderLabel.Dcent]: false,
    [ProviderLabel.Frame]: false,
    [ProviderLabel.HuobiWallet]: false,
    [ProviderLabel.HyperPay]: false,
    [ProviderLabel.ImToken]: false,
    [ProviderLabel.Liquality]: false,
    [ProviderLabel.MeetOne]: false,
    [ProviderLabel.MyKey]: false,
    [ProviderLabel.OwnBit]: false,
    [ProviderLabel.TokenPocket]: false,
    [ProviderLabel.TP]: false,
    [ProviderLabel.XDEFI]: false,
    [ProviderLabel.OneInch]: false,
    [ProviderLabel.Tokenary]: false,
    [ProviderLabel.DeFiWallet]: false,
    [ProviderLabel.GameStop]: false,
    [ProviderLabel.Rabby]: false,
    [ProviderLabel.MathWallet]: false,
    [ProviderLabel.BitKeep]: false,
    [ProviderLabel.Sequence]: false,
    [ProviderLabel.Core]: false,
    [ProviderLabel.Bitski]: false,
    [ProviderLabel.Enkrypt]: false,
    [ProviderLabel.Zeal]: false,
    [ProviderLabel.Exodus]: false,
    [ProviderLabel.Frontier]: false,
    [ProviderLabel.Rainbow]: false,
    [ProviderLabel.DeFiWallet]: false,
    [ProviderLabel.ApexWallet]: false,
    [ProviderLabel.BifrostWallet]: false,
  },
});

/*addWallet connect*/
export const wcV2InitOptions = {
  version: 2,
  /**
   * Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
   */
  projectId: "d064ff3dbd855cd7e2b81c1e9d1ed2ed",
};

// initialize the module with options
// If version isn't set it will default to V1 until V1 sunset
export const walletConnect = walletConnectModule(wcV2InitOptions);
/*End Wallet connect*/
export const onboard = Onboard({
  wallets: [injected, torus, walletConnect],
  // connect: { autoConnectLastWallet: true, showSidebar: false },
  connect: { showSidebar: false },
  accountCenter: { desktop: { enabled: false }, mobile: { enabled: false } },

  chains: [
    {
      id: 5,
      token: "ETH",
      label: "Goerli",
      rpcUrl: MAINNET_RPC_URL,
    },
  ],
});

const Header = () => {
  const [account, setAccount] = useRecoilState(accountState);
  const [isFactoryOwner, setIsFactoryOwner] =
    useRecoilState(isFactoryOwnerState);
  const [referralReduction, setReferralReduction] = useRecoilState(
    referralReductionState
  );
  const [referralAddress, setReferralAddress] =
    useRecoilState(referralAddressState);

  const [error, setError] = useState();

  const [isConnecting, setIsConnecting] = useRecoilState(isConnectingState);
  const [isAvalanche, setIsAvalanche] = useState(false);
  const [provider, setProvider] = useState(ethereum);
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(true);
  const [isMetamaskAlertOpened, setIsMetamaskAlertOpened] = useState(false);
  const [webSocketProps, setWebSocketProps] = useState(null);
  const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserState);
  const [networkId, setNetworkId] = useRecoilState(networkIdState);

  
  const [kycStatusVersion, setKycStatusVersion] = useRecoilState(
    kycStatusVersionState
  );

  const [isMobile, setIsMobile] = useState(false);
  const [whitelistActivated, setWhitelistActivated] = useRecoilState(
    whitelistActivatedState
  );

  const [collectionName, setCollectionName] = useRecoilState(collectionNameState)

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [web3Api, setWeb3Api] = useRecoilState(web3ApiState);
  const changeNetwork = async ({ networkName, setError }) => {
    try {
      if (!provider) throw new Error("No crypto wallet found");
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            ...networks[networkName],
          },
        ],
      });
      setNetworkId(networkName);
    } catch (err) {
      setError(err.message);
    }
  };

  const connectWallet = async () => {
    try {
      // if (!ethereum) {
      //   setIsMetamaskAlertOpened(true);
      //   return;
      //   //return alert('Please install metamask')
      // }
      let provider;
      // Loading...
      setIsConnecting(true);
      // Get all the accounts and the user will be able to choose one.
      let wallets;
      try {
        let wallet = window.localStorage.getItem("wallet");
        if (
          wallet !== undefined &&
          wallet !== "undefined" &&
          wallet !== null &&
          wallet !== "null"
        ) {
          wallets = await onboard.connectWallet({ autoSelect: wallet });
        } else {
          wallets = await onboard.connectWallet();
        }

        if (wallets[0]) {
          setWeb3Api((prevState) => {
            return { ...prevState, isLoading: true, success: false };
          });
          provider = new ethers.providers.Web3Provider(
            wallets[0].provider,
            "any"
          );

          window.onBoardProvider = wallets[0].provider;
          setWeb3Api((prevState) => {
            return {
              ...prevState,
              isLoading: false,
              success: true,
              provider: provider,
            };
          });
          setProvider(provider.provider);
          const accounts = await provider.provider.request({
            method: "eth_requestAccounts",
          });

          // debugger;
          if (
            (localStorage.getItem("loggedOut") == "true" ||
              localStorage.getItem("loggedOut") == null) &&
            localStorage.getItem("token") == null
          ) {
            const signature = await provider.provider.request({
              method: "personal_sign",
              params: [accounts[0], accounts[0]],
            });
            localStorage.setItem(
              "wallet",
              onboard.state.get().wallets[0].label
            );

            await handleRegister(collectionName, accounts[0], signature);
          }

          localStorage.setItem("loggedOut", "false");

          // Loading...
          setIsConnecting(false);
          setAccount(accounts[0]);
          // Test provider avalanche

          if (provider.provider.chainId !== AppParams.AppChainId) {
            await handleNetworkSwitch(AppParams.AppChainId);
            // setIsAvalanche(false)
          } else {
            setIsAvalanche(true);
          }

          window.localStorage.setItem(
            "wallet",
            onboard.state.get().wallets[0].label
          );
          //console.log("isAvalanche: ", isAvalanche);

          provider &&
            provider.provider.on("accountsChanged", function (accounts) {
              console.log("accountChanged", accounts);
              setAccount(accounts[0]);
            });
          setNetworkId(provider.provider.chainId);
        } else {
          setIsConnecting(false);
        }
      } catch (error) {
        console.log(error);
        localStorage.setItem("loggedOut", "true");

        setIsConnecting(false);
        return;
      }
    } catch (error) {
      console.log(error);
      localStorage.setItem("loggedOut", "true");
      setIsConnecting(false);
      throw new Error("No ethereum object.");
    }
  };

  useEffect(async () => {
    if (!account) {
      setReferralReduction(0);
      setIsFactoryOwner(false);
      return;
    }
    const provider = web3Api.provider;
    const signer = await provider.getSigner();
    fetchIsFactoryContractOwner(signer);
  }, [account, networkId, provider]);

  const loadProvider = useCallback(async () => {
    if (!account) {
      setReferralReduction(0);
      setIsFactoryOwner(false);
      return;
    }
    if (provider) return;

    const wallets = await onboard.connectWallet({ autoSelect: true });
    if (wallets[0]) {
      const provider = new ethers.providers.Web3Provider(
        wallets[0].provider,
        "any"
      );

      /*if (provider.chainId !== AppParams.AppChainId) {
        setReferralReduction(0);
        return;
      }*/
      const signer = await provider.provider.getSigner();

      fetchIsFactoryContractOwner(signer);

      /*const contractMarketplace = new ethers.Contract(
        AppParams.MARKET_ADDRESS,
        MarketplaceAbi,
        signer
      );
  
      let referrals = await contractMarketplace.getReferralsList(account);
      if (
        referrals[0].account != "0x0000000000000000000000000000000000000000"
      ) {
        let reduction = parseInt(
          await contractMarketplace.referralLevelReward(0)
        );
        setReferralReduction(reduction / 10 ** 6);
      } else {
        setReferralReduction(0);
      }*/
      return provider.provider;
    }
  }, [account, networkId, provider]);

  const fetchIsFactoryContractOwner = (signer) => {
    const contractFactory = new ethers.Contract(
      AppParams.NFT_FACTORY_ADDRESS,
      NFTFactoryAbi,
      signer
    );

    contractFactory.isOwner(account).then((isFactoryOwner) => {
      setIsFactoryOwner(isFactoryOwner);
    });
  };

  // const checkIfWalletIsConnected = async () => {
  //   try {
  //     //if (!ethereum) return alert('Please install metamask')
  //     if (provider) return;
  //     const accounts = await provider.request({ method: "eth_accounts" });

  //     if (accounts.length) {
  //       setAccount(accounts[0]);
  //     } else {
  //       console.log("No Accounts found");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     throw new Error("No ethereum object.");
  //   }
  // };

  console.log("current account: ", account);

  // Control current Network via the API exposed by Metamask! (A nice user experience perspective)
  // If for example the user hasn't the BSC Mainnet in his wallet, then it will ask him
  // to add it inside his wallet
  const handleNetworkSwitch = async (networkName) => {
    setError();
    await changeNetwork({ networkName, setError });
  };
  const handleNetworkDisconnect = async () => {
    setAccount("");
    const [primaryWallet] = onboard.state.get().wallets;
    await onboard.disconnectWallet({ label: primaryWallet.label });
    localStorage.setItem("loggedOut", "true");
    localStorage.setItem("wallet", null);
    localStorage.removeItem("token");
    setWeb3Api((prevState) => {
      return { ...prevState, isLoading: false, success: false, provider: null };
    });
    navigate("/nfts/"+localStorage.getItem("collection"));
  };

  const networkChanged = (chainId) => {
    //window.location.reload()
    setNetworkId(chainId);
    console.log({ chainId });
    // Test Avalanche Network
    // if (chainId === '0xa86a') {
    //   setIsAvalanche(true)
    // } else {
    //   setIsAvalanche(false)
    // }
  };

  const detectProvider = async () => {
    let provider;
    provider = await loadProvider();
    //console.log('provider: ', provider.networkVersion)
    return provider;
  };

  /*const [avaxValue, setAvaxValue] = useRecoilState(avaxValueUsd);
  
  const fetchAvaxValue = async () => {
    axios.get("https://api.traderjoexyz.com/priceusd/avax").then((result) => {
      console.log("result.data: ", result.data);
      setAvaxValue(ethers.utils.formatEther(result.data + ""));
    });
  };*/
  const init = async () => {
    setReferralAddress(localStorage.getItem("referralAddress"));

    // fetchAvaxValue
    //fetchAvaxValue();
    // Detect Provider
    if (
      localStorage.getItem("loggedOut") == "true" ||
      localStorage.getItem("loggedOut") == null
    )
      return;

    // If not avalanche network version
    // if (provider.networkVersion !== '43114') {
    // }
    connectWallet();

    if (
      (localStorage.getItem("loggedOut") == "true" ||
        localStorage.getItem("loggedOut") == null) &&
      window.location.pathname != "/"
    ) {
      navigate("/");
    }
    // checkIfWalletIsConnected();
  };

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) &&
      "ontouchstart" in document.documentElement
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    init();
  }, []);

  useEffect(() => {
    try {
      provider && provider.on("chainChanged", networkChanged);
    } catch (e) {
      console.error(e);
    }

    return () => {
      provider && provider.removeListener("chainChanged", networkChanged);
    };
  }, [provider]);

  // The second useEffect will run each time the provider is updated
  useEffect(() => {
    if (provider) {
      if (provider !== window.ethereum) {
        console.error(
          "Not window.ethereum provider. Do you have mutiple wallets installed ?"
        );
      }
      // If we have a provider that means that metamask is well installed
      setIsMetamaskInstalled(true);
    }
  }, [provider]);

  useEffect(() => {
    if (
      loggedInUser == null ||
      loggedInUser.websocketId == undefined ||
      loggedInUser.websocketId == null
    )
      return;
    setWebSocketProps({
      websocketURL: `${AppParams.WS_BACKEND}?token=`,
      webSocketToken: loggedInUser.websocketId,
      privateNotificationsEndpoint: "/user/events/private-notify",
      onPrivateNotificationReceived: (message) => {
        console.log("message ws: ", message);
        if (message === "USER_KYC_STATUS_UPDATED") {
          let r = (Math.random() + 1).toString(36).substring(7);
          setKycStatusVersion(r);
        }
      },
    });
  }, [loggedInUser]);



  const handleAfterLangChange = (lang) => {
    httpService
      .put(`${AppParams.BACKEND_URL}/users/set-lang?langue=${lang}`)
      .then((response) => {
        console.log("lang", response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  const onUserInfoFetched = (userInfo) => {
    console.log("userInfo",userInfo)
    const projectName = userInfo.projectConfiguration.identifier
    if ((projectName != null && projectName != localStorage.getItem("collection") || (account !== '' && userInfo.publicAddress.toLocaleLowerCase() !== account.toLocaleLowerCase()))) {
      /*localStorage.setItem("loggedOut", "true");
      localStorage.setItem("wallet", null);
      localStorage.removeItem("token");
      connectWallet();
      setLoggedInUser(userInfo)*/
      handleNetworkDisconnect()
    }
  }
  const onUserInfoError = (error) => {
    /*localStorage.setItem("loggedOut", "true");
    localStorage.setItem("wallet", null);
    localStorage.removeItem("token");
    connectWallet();*/
  }

  // useEffect(() => {
  //   checkIfWalletIsConnected()
  // }, [])

  return (
    <header>
      <UserInfos onUserInfoError={(error)=>onUserInfoError(error)} onUserInfoFetched={(userInfo) => onUserInfoFetched(userInfo)} account={account}></UserInfos>
      {webSocketProps != null ? (
        <WebsocketClientComponent
          {...webSocketProps}
        ></WebsocketClientComponent>
      ) : null}
      <Navbar
        bg="primary"
        variant="dark"
        expand="lg"
        collapseOnSelect
        className="pt-3"
      >
        <Container
          fluid
        // fluid
        >
          <LinkContainer to={"/nfts/" + collectionName}>
            <Navbar.Brand>
              <img src="/images/logo-ant.png" height="35" alt="logoopen2be" />
            </Navbar.Brand>
          </LinkContainer>
          {isMobile && (
            <div className="element-hide-l">
              <NotificationBar></NotificationBar>
            </div>
          )}
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <LinkContainer to={"/nfts/" + collectionName}>
                <Nav.Link>{t("Marketplace")}</Nav.Link>
              </LinkContainer>
              {account != null && account != "" ? (
                <LinkContainer
                  to={"/my-assets/" + collectionName}
                >
                  <Nav.Link>{t("My Digital Assets")}</Nav.Link>
                </LinkContainer>
              ) : null}
              {account != null && isFactoryOwner != "" ? (
                <LinkContainer to="/manage-apps">
                  <Nav.Link>{t("Manage apps")}</Nav.Link>
                </LinkContainer>
              ) : null}
              <LinkContainer
                to={`/how-it-works/${collectionName}`}
              >
                <Nav.Link>{t("How it works")}</Nav.Link>
              </LinkContainer>
            </Nav>

            <Nav.Link>
              <SwitchDesign />
            </Nav.Link>

            {account &&
              networkId !== null &&
              networkId != AppParams.AppChainId ? (
              <button
                type="button"
                className="btn btn-primary btnConn"
                onClick={connectWallet}
                style={{ marginRight: "10px" }}
              >
                {t("Switch network") + " " + AppParams.AppChainName}
              </button>
            ) : null}

            {!account ? (
              <button
                type="button"
                className="btn btn-primary btnConn"
                onClick={connectWallet}
                disabled={isConnecting}
              >
                {!isConnecting && t("Connect wallet")}
                {isConnecting && `${t("Connecting wallet")}...`}
              </button>
            ) : (
              <div style={{ display: "flex" }}>
                {!isMobile && (
                  <div className="hideMobile">
                    <NotificationBar></NotificationBar>
                  </div>
                )}
                <DropdownButton
                  id="dropdown-basic-button"
                  title={shortenAddress(account)}
                  className="padd"
                >
                  <Dropdown.Item onClick={()=>navigate(`/profile/${collectionName}`)}>
                    {t("Referral program")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleNetworkDisconnect()}>
                    {t("Log out")}
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            )}
            <div>
              <LangSelector
                supportedLangs={[
                  {
                    name: "en",
                    image: "/images/fr.png",
                  },
                  {
                    name: "fr",
                    image: "/images/en.png",
                  },
                  // ,
                  // {
                  //     name: "es",
                  //     image: "/images/es.png"
                  // }
                ]}
                afterLangChange={handleAfterLangChange}
              ></LangSelector>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        className="Imetamask"
        show={isMetamaskAlertOpened}
        onHide={(e) => {
          setIsMetamaskAlertOpened(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          {t("To keep your O2B's safe use metamask wallet")}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setIsMetamaskAlertOpened(false);
            }}
          >
            {t("Ok")}
          </Button>
        </Modal.Footer>
      </Modal>
      {account != null && account != "" && whitelistActivated == true ? (
        <KycForm />
      ) : null}
    </header>
  );
};

export default Header;
