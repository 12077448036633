import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

import { ethers } from 'ethers'
import axios from 'axios'

import {
  AppParams,
  TokensClient,
} from '../config'

import BuyNFT from '../components/BuyNFT'
import { myTokenDetails } from '../services/graphql'
import { useRecoilState } from 'recoil'
import { referralReductionState } from '../services/atoms'
import BuyNFTMinterMarketplace from './BuyNFTMinterMarketplace'
import { useTranslation } from 'react-i18next'

const CollectionCard = ({ collection,collectionName }) => {


  const [referralReduction, setReferralReduction] = useRecoilState(referralReductionState)

  const { t, i18n } = useTranslation();

  const [item, setItem] = useState({
    tokenId: <hr size='5' color='#7373ab' width='40' />,
    image: '/images/Spin-det.svg',
    name: <hr size='5' color='#7373ab' width='70' />,
    description: <hr size='10' color='#7373ab' width='90' />,
  })


  useEffect(() => {
    //loadNFTs()
  }, [])
 useEffect(async () => {
     /*TokensClient.query({
      query: myTokenDetails,
      fetchPolicy: 'network-only',
      variables: {
        contractAddress: nft.contract.id,
        tokenId: parseInt(nft.tokenId),
      },
    }).then((res) => {*/
      axios.get(collection.categoryURI.replace("ipfs://", AppParams.IPFS_GATWAY)).then((meta) => {
        let tokensMeta = {
          image: meta.data.image.replace("ipfs://", AppParams.IPFS_GATWAY),
          name: meta.data.name,
          title: meta.data.title,
          titleFr: meta.data.titleFr,
          description: meta.data.description
          

        }
        setItem(tokensMeta)
      /*})*/
    })
  }, [collection.name])

  function showOnExplorer(event) {
    /*event.stopPropagation()
    window.open(
      `${AppParams.BLOCK_EXPLORER_URL}token/${nft.contract.id}?a=${nft.tokenId}`,
      '_blanc'
    )*/
  }

  // parse float
  function circumference(r) {
    if (Number.isNaN(Number.parseFloat(r))) {
      return 0
    }
    return parseFloat(r)
  }

  return (
    <Card className='my-3 nftC'>
      <Card.Body>
        <div style={{ display: 'flex' }}>
          <div>
            <span className='CardTokenId' onClick={showOnExplorer}>
              #{collection.name}
            </span>
          </div>
          <div className="CardTokenTokenClass">
            <div className='codeD'>{collection.name}</div>
          </div>
        </div>
       <div className='titleN' style={{ paddingTop: 10 }}>
          <Link to={`/collection/${collection.tokenContract.id}/${collection.name}`}>
            <Card.Title as='div'>
              <span>{item.name}</span>
            </Card.Title>
          </Link>
        </div>
 
        {/*<Card.Text as='div' className='desc'>
          {item.description}
  </Card.Text>*/}
       <div style={{textAlign:'center'}} className="imgC"> 
        <Link to={`/collection/${collection.tokenContract.id}/${collection.name}`}>
          <Card.Img variant='top' src={item.image} />
        </Link>
        </div>
        <div className='positionDe'>
            <Card.Text className='CardTokenDescription'>
              {i18n.language == 'fr' ? item?.titleFr : item?.title}
            </Card.Text>
          <div className='AlignBc'>

            <Card.Text as='h3'>
              {circumference(collection.price * (1-referralReduction/100)).toLocaleString().replace(",", ".")}{' '}{collection.paymentTokenSymbol}
            </Card.Text>



          </div>
          <div className='AlignBc'>
            <BuyNFTMinterMarketplace
              props={{
                collectionIndex: collection.tokenContract.marketPlaceIndex,
                categoryName:collection.name,
                price:collection.price,
                paymentToken:collection.paymentToken,
                paymentSymbol:collection.paymentTokenSymbol,
                collectionName:collectionName,
                collectionAddress: collection.tokenContract.id
              }}
            ></BuyNFTMinterMarketplace>
            </div>
        </div>
      </Card.Body>
    </Card>
  )
}


export default CollectionCard
