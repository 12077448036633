import React, { forwardRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
//import Spinner from 'react-spinner-material';
import { AppParams, MinterMarketPlaceClient} from "../../config";
import { Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useNavigate } from 'react-router-dom'
import { referralsList } from "../../services/graphql";
import { useTranslation } from "react-i18next";


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};






export default function ReferralsList({account = null, collectionAddress=null,totalCollectedReferralrewardChanged=0,paymentTokenSymbol=null}) {

    const navigate = useNavigate()
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState([]);
    const { t, i18n } = useTranslation();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15, //set different default page size by initializing the state here
      });

      const tableColumnConfig = [
        {
            title: t('Address'),
            field: 'publicAddress',
            render: row => {
                let m = row.child.publicAddress && row.child.publicAddress.match && row.child.publicAddress.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
                return <div className="table-cell"><div className="tx-field-tables" data-toggle="tooltip" data-placement="top" titre={row.child.publicAddress} ><a target="_blank" rel="noopener noreferrer" href={`${AppParams.BLOCK_EXPLORER_URL}address/${row.child.publicAddress}`}>{m ? m[1] + '...' + m[3] : ''}</a></div></div>;
            }
        },
        {
            title: t('Level'),
            field: 'level',
            render: (row) => {
                return <div className="table-cell">{row.level}</div>
            }
    
        },
        {
            title: t('Direct parent'),
            field: 'parent',
            render: row => {
                let m = row.child.parent.publicAddress && row.child.parent.publicAddress.match && row.child.parent.publicAddress.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
                return <div className="table-cell"><div className="tx-field-tables" data-toggle="tooltip" data-placement="top" titre={row.child.parent.publicAddress} ><a target="_blank" rel="noopener noreferrer" href={`${AppParams.BLOCK_EXPLORER_URL}address/${row.child.parent.publicAddress}`}>{m ? m[1] + '...' + m[3] : ''}</a></div></div>;
            }
    
        },
        {
            title: t('Profit'),
            field: 'totalDistributedAmount',
            render: (row) => {
                return <div className="table-cell">{row.totalDistributedAmount} {paymentTokenSymbol}</div>
            }
    
        }
    ]

    const remoteData = (query) => {
        console.log("Query object - ", query)
    
        if(query.account == null || query.collectionAddress == null){
            totalCollectedReferralrewardChanged(0);
            return;
        } 
    
        return MinterMarketPlaceClient.query({
            query: referralsList,
            variables: {
                parentId : query.collectionAddress+"-"+query.account.toLowerCase()
    
            }
        }).then((res) => {
            console.log("referrals",res)
            /*for (var i = 0; i < res.data.tokenContracts.length; i++) {
                res.data.tokenContracts[i].poolParticipationRate = (res.data.tokenContracts[i].poolParticipation / res.data.tokenContracts[0].totalPoolValue) * 100
            }*/
            setTimeout(() => {
                //window.$('div[data-toggle]').tooltip({});
            }, 1000);
            console.log("aaa res",res)
            //console.log("aaa res 2",flattenHierarchy(res.data.users[0]))
            setData(res.data.referralsDistributions)
            if(res.data.referralsDistributions[0]?.parent?.totalCollectedReferralreward != null){
                totalCollectedReferralrewardChanged(res.data.referralsDistributions[0].parent.totalCollectedReferralreward)
            }
            /*setData({
                //data: flattenHierarchy(res.data.users[0]),
                data: res.data.referralsDistributions,
                page: 0,
                //page: query.page,
                //totalCount: res.data.alls[0].numTokenContracts === undefined ? 0 : (parseInt(res.data.alls[0].numTokenContracts))
                totalCount: 100
            })*/
        })
    }

    useEffect(() => {
        setTimeout(() => {
            setVisible(true);
        }, 1000);
    }, [])

    useEffect(()=>{
        if(account == null || collectionAddress == null) return;

        remoteData({
            account,
            collectionAddress
        })
    },[account,collectionAddress])

    return (
        <>

            <div className="textCenter textM"><h2 className="w-700">{t('Referrals List')}</h2></div>
            <div className=" row ANTRectangleTS mb-5 mt-5" style={{ paddingBottom: 0 }}>

                <div style={{ minHeight: 300 }} className="tabalance">
                    {
                        visible ? (
                            <MaterialTable
                                icons={tableIcons}
                                columns={tableColumnConfig}
                                data={data}
                                options={{
                                    toolbar: false,
                                    showFirstLastPageButtons: false,
                                }}

                            />
                        ) : null
                    }
                </div>

            </div>
        </>
    )
}
