import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Row, Col, Image, ListGroup, Card, Button } from 'react-bootstrap'
import axios from 'axios'

import {
  AppParams,
  MinterMarketPlaceClient,
  TokensClient,
} from '../config'

import { categoryDetailsMinterMarketPlace, collectionDetails, collectionDetailsById } from '../services/graphql'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { accountState, collectionNameState, configurationState, referralReductionState, refreshIndex, whitelistActivatedState } from '../services/atoms'
import BuyNFTMinterMarketplace from '../components/BuyNFTMinterMarketplace'
import httpService from '../services/httpService'
import { ethers } from 'ethers'
import { MinterMarketplaceAbi } from '../artifacts/contracts-abis/MinterMarketplace-abi'
import SwitchDesign from '../components/SwitchDesign'

const CollectionDetails = () => {
  const { contractAddress, category } = useParams()
  const [nft, setNft] = useState([])
  const { t, i18n } = useTranslation();

  const [referralReduction, setReferralReduction] = useRecoilState(referralReductionState)
  const [whitelistActivated, setWhitelistActivated] = useRecoilState(whitelistActivatedState)

  const [configuration, setConfiguration] = useRecoilState(configurationState)
  const [account, setAccount] = useRecoilState(accountState)
  const [refresh, setRefresh] = useRecoilState(refreshIndex);

  const [collectionName, setCollectionName] = useRecoilState(collectionNameState)

  const [item, setItem] = useState({
    image: '/images/Spin-det.svg',
    name: '--',
    category: '--',
  })


  useEffect(async () => {
    if(item.whitelist == undefined) return;
    console.log("whitelist",item.whitelist)
    if (item.whitelist != "0x0000000000000000000000000000000000000000") {
      setWhitelistActivated(true)
    }else{
      setWhitelistActivated(false)
    }
    
  }, [item.whitelist])


  useEffect(async () => {

    if (account == '' || !contractAddress) {
      setReferralReduction(0)
      return;
    } else {

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const contractMarketplace = new ethers.Contract(
        AppParams.MINTER_MARKET_ADDRESS,
        MinterMarketplaceAbi,
        signer
      );
      
      let referrals = await contractMarketplace.getReferralsList(contractAddress, account);
      if (referrals[0].account != "0x0000000000000000000000000000000000000000") {
        let reduction = parseInt(await contractMarketplace.referralLevelReward(contractAddress, 0));
        setReferralReduction(reduction / 10 ** 6)

      } else {
        setReferralReduction(0)
      }
    }
  }, [contractAddress, account, refresh])


  useEffect(() => {
    if (collectionName == null) return;

    if (localStorage.getItem("collection") == null || localStorage.getItem("collection") == "" || localStorage.getItem("collection") == "null") {
      localStorage.setItem("collection", collectionName)
    }

    httpService.get(
      `${AppParams.BACKEND_URL}/config/projects?projectID=${collectionName}`
      ,
    )
      .then(response => {
        setConfiguration(response.data)
      })
      .catch(error => {
        console.log(error)
      });
  }, [collectionName])

  useEffect(() => {
    // Load NFTS
    loadNFTs()
  }, [])

  async function loadNFTs() {

    MinterMarketPlaceClient.query({
      query: categoryDetailsMinterMarketPlace,
      fetchPolicy: "network-only",
      variables: {
        collectionAddress: contractAddress,
        category: category
      }
    }).then((res) => {
      axios.get(res.data.tokenClasses[0].categoryURI.replace("ipfs://", AppParams.IPFS_GATWAY)).then((meta) => {
        let tokensMeta = {
          image: meta.data.image.replace("ipfs://", AppParams.IPFS_GATWAY),
          name: meta.data.name,
          descriptionFr: meta.data.descriptionFr,
          descriptionEn: meta.data.descriptionEn,
          owner: res.data.tokenClasses[0].tokenContract.seller,
          //collectionName: res.data.tokenClasses[0].tokenContract.name,
          collectionURI: res.data.tokenClasses[0].tokenContract.collectionURI,
          collectionIndex: res.data.tokenClasses[0].tokenContract.marketPlaceIndex,
          whitelist: res.data.tokenClasses[0].tokenContract.whitelist,
          price: res.data.tokenClasses[0].price,
          paymentTokenSymbol: res.data.tokenClasses[0].paymentTokenSymbol,
          paymentToken: res.data.tokenClasses[0].paymentToken,

        }
        setItem(Object.assign({ ...item, ...tokensMeta }))
      })
    })


    TokensClient.query({
      query: collectionDetailsById,
      fetchPolicy: 'network-only',
      variables: {
        collection: contractAddress,
      },
    }).then((res) => {
      setCollectionName(res.data.tokenContracts[0].name)
    })

  }


  function showOnExplorer(event) {
    event.stopPropagation()
    window.open(
      `${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}`,
      '_blanc'
    )
  }

  function circumference(r) {
    if (Number.isNaN(Number.parseFloat(r))) {
      return 0
    }
    return parseFloat(r)
  }

  function downloadURI(uri, tokenClass) {

    fetch(uri)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = tokenClass + '.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })

  }

  return (
    <>
      <SwitchDesign collectionName={collectionName} ></SwitchDesign>
      <div className='screenD container'>
        <Link className='btn btn-outline-primary my-2 pl-0' to={'/nfts/' + collectionName}>
          <i className='fa-solid fa-angle-left'></i> {t("Back")}
        </Link>
        <Row>
          <Col>
            <span
              className='codeD'
              style={{ cursor: 'pointer' }}
              onClick={showOnExplorer}
            >
              #{category}
            </span>
          </Col>
        </Row>
        <Card.Text as='h3'>{nft?.name}</Card.Text>
        <Row className="rowD">
          <Col md={6}>
            {/* <Card.Text as='h3'>{nft?.itemId}</Card.Text> */}

            <div className='p25'>
              <Image
                src={item?.image}
                alt={item?.name}
                style={{ borderRadius: 20 }}
                fluid
              />
            </div>



          </Col>
          <Col md={6} className="colS">

            <div>
              <h4>{t("Price")}</h4>
              <ListGroup variant='flush' className='card cardab'>
                <ListGroup.Item>
                  <Row>
                    <Col>
                      <div className='priceD'>
                        <div>
                          {item.price != null ?
                            referralReduction > 0 ?
                              <h4>
                                <div>
                                  <span className='old-price'>
                                    {circumference(item.price).toLocaleString().replace(",", ".")}{' '} {item.paymentTokenSymbol}
                                  </span>
                                  <span>
                                    {" (-" + referralReduction + '% OFF)'}
                                  </span>
                                </div>
                                {circumference(item.price * (1 - referralReduction / 100)).toLocaleString().replace(",", ".")}{' '}{item.paymentTokenSymbol}
                              </h4>
                              :
                              <h4>
                                {circumference(item.price).toLocaleString().replace(",", ".")}{' '} {item.paymentTokenSymbol}
                              </h4>
                            :
                            <h4>--</h4>
                          }
                        </div>
                        <BuyNFTMinterMarketplace
                          props={{
                            collectionIndex: item.collectionIndex,
                            categoryName: category,
                            price: item.price,
                            paymentToken: item.paymentToken,
                            paymentSymbol: item.paymentTokenSymbol,
                            collectionName: collectionName,
                            collectionAddress: contractAddress
                          }}
                        ></BuyNFTMinterMarketplace>
                      </div>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div>
              <h4>{t("About")}</h4>
              <ListGroup variant='flush' className='card cardab'>
                <ListGroup.Item className="priceD">
                  {t("Contract")} <a href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}`} target="_blank" className="itemS"><span style={{ color: '#BAFF85 !important' }}>{contractAddress}</span></a>
                </ListGroup.Item>
                <ListGroup.Item className="priceD">
                  {t("Owner")} <a href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${item.owner}`} target="_blank" className="itemS"><span style={{ color: '#BAFF85 !important' }}>{item?.owner == null ? "--" : item?.owner}</span></a>
                </ListGroup.Item>



                <ListGroup.Item className="priceD">
                  {t("Class")} <span>{category}</span>{' '}
                </ListGroup.Item>


              </ListGroup>
            </div>


          </Col>

        </Row>
        <Row>
          <Col>
            <div>
              <h4>{t("More")}</h4>
              <ListGroup variant='flush' className='card cardab'>
                <ListGroup.Item className="priceD">
                  {t("General conditions of use and sale")} <a href={i18n.language == 'fr' ? configuration?.conditionsGeneralesUriFr: configuration?.conditionsGeneralesUriEn} target="_blank" className="itemS"><span style={{ color: '#BAFF85 !important' }}>{i18n.language == 'fr' ? configuration?.conditionsGeneralesUriFr: configuration?.conditionsGeneralesUriEn}</span></a>
                </ListGroup.Item>

                {configuration?.projectPdfDescriptionUriFr.length > 3 &&
                  <ListGroup.Item className="priceD">
                    {t("Project info")} <a href={i18n.language == 'fr' ? configuration?.projectPdfDescriptionUriFr: configuration?.projectPdfDescriptionUriEn} className="itemS" target="_blank"><span style={{ color: '#BAFF85 !important' }}>{i18n.language == 'fr' ? configuration?.projectPdfDescriptionUriFr: configuration?.projectPdfDescriptionUriEn}</span></a>

                  </ListGroup.Item>
                }
                <ListGroup.Item>
                  <div style={{ textAlign: 'center' }}>{t("Description")}</div> <br /> <div className="spandesc"><span>{
                    i18n.language == 'fr' ? item?.descriptionFr : item?.descriptionEn
                  }</span></div>{' '}
                </ListGroup.Item>
              </ListGroup>

            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default CollectionDetails
