import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function CollectionRoleManagement({contractAddress,marketplaceContract}) {
    const { t, i18n } = useTranslation();
    const [userToGrantSeller, setUserToGrantSeller] = useState(null);
    const [userToGrantSellerAdmin, setUserToGrantSellerAdmin] = useState(null);

    

    useEffect(async () => {

        /*const contract = marketplaceContract;

        let REFERRALS_MAX_LEVELS = await contract.REFERRALS_MAX_LEVELS(contractAddress);
        setReferralMaxLevels(REFERRALS_MAX_LEVELS)*/

    }, [contractAddress])


    const grantSellerRole = async () => {

        const contract = marketplaceContract;

        contract.grantSellerRole(contractAddress, userToGrantSeller).then(tx => {
            console.log(tx)
        }).catch(err => {
            console.error(err);
        })
    }
    const grantSellerRoleSellerAdmin = async () => {

        const contract = marketplaceContract;

        contract.grantRole("0x9f48a113a78d199137b1242d343cc3cec938654e32c0577db7da685fb2f6040a", userToGrantSellerAdmin).then(tx => {
            console.log(tx)
        }).catch(err => {
            console.error(err);
        })
    }

    return (
        <div style={{ display: "flex" }}>
            <div >
                <div style={{ display: "grid" }}>
                    <label htmlFor="userToGrantInput">User to grant Seller ADMIN role:</label>
                    <input type="text" id="userToGrantInput" value={userToGrantSellerAdmin} onChange={(e) => setUserToGrantSellerAdmin(e.target.value)} />
                </div>
                <Button onClick={grantSellerRoleSellerAdmin}>{t("GRANT")}</Button>

                
                <div style={{ display: "grid" }}>
                    <label htmlFor="userToGrantInput">User to grant Seller role:</label>
                    <input type="text" id="userToGrantInput" value={userToGrantSeller} onChange={(e) => setUserToGrantSeller(e.target.value)} />
                </div>
                <Button onClick={grantSellerRole}>{t("GRANT")}</Button>
            </div>
        </div>

    )
}
