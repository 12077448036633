import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MinterMarketplaceAbi } from '../artifacts/contracts-abis/MinterMarketplace-abi';
import { AppParams } from '../config';
import ReferralsParamAdmin from '../components/ReferralsParamAdmin';
import CollectionRoleManagement from '../components/CollectionRoleManagement';
import WhitelistRoles from '../components/WhitelistRoles';
import ContractFunctionList from '../components/ContractFunctionList';
import { MarketplaceAbi } from '../artifacts/contracts-abis/Marketplace-abi';


export default function ManageCollection() {
    const { t, i18n } = useTranslation();
    const { contractAddress,marketplaceAddress } = useParams()
    
    const [marketplaceContract,setMarketplaceContract] = useState(null)
    const [collectionAddress,setCollectionAddress] = useState(null)

    useEffect(async () => {

        getMarketplaceContract();
        setCollectionAddress(contractAddress);

    }, [contractAddress])

    const getMarketplaceContract = () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const contract = new ethers.Contract(
            marketplaceAddress,
            MinterMarketplaceAbi,
            signer
        );
        setMarketplaceContract(contract);
    }

    return (
        <div>
            <CollectionRoleManagement contractAddress={collectionAddress} marketplaceContract={marketplaceContract}></CollectionRoleManagement>
            <ReferralsParamAdmin  contractAddress={collectionAddress} marketplaceContract={marketplaceContract}></ReferralsParamAdmin>
            <WhitelistRoles></WhitelistRoles>
            <ContractFunctionList abi={MinterMarketplaceAbi} ADDRESS={marketplaceAddress}></ContractFunctionList>
        </div>

    )
}
