import { ethers } from "ethers";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Spinner, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ERC20Abi } from "../artifacts/contracts-abis/ERC20-abi";
import { AppParams } from "../config";
import {
  pendingTransactions,
  accountState,
  referralAddressState,
  kycState,
  networkIdState,
  web3ApiState,
  isConnectingState,
  whitelistActivatedState,
} from "../services/atoms";
import { useRecoilState, useRecoilValue } from "recoil";

import { networks } from "../config";
import ReferralModal from "./ReferralModal";
import CGSModal from "./CGSModal";
import { useTranslation } from "react-i18next";
import { MinterMarketplaceAbi } from "../artifacts/contracts-abis/MinterMarketplace-abi";
import PurchaseDisclaimer from "./PurchaseDisclaimer";
import Onboard from "@web3-onboard/core";
import injectedModule, { ProviderLabel } from "@web3-onboard/injected-wallets";
import torusModule from "@web3-onboard/torus";
import walletConnectModule from "@web3-onboard/walletconnect";
import httpService from "../services/httpService";
import { handleRegister } from "../services/authentication";
import { onboard } from "./Header";




const BuyNFTMinterMarketplace = (props) => {
  const [pendings, setPendings] = useRecoilState(pendingTransactions);
  const [whitelistActivated, setWhitelistActivated] = useRecoilState(
    whitelistActivatedState
  );

  const {
    collectionIndex,
    categoryName,
    price,
    collectionName,
    paymentToken,
    paymentTokenSymbol,
    collectionAddress,
  } = props.props;

  useEffect(() => {
    console.log("collectionName", collectionName);
  }, collectionName);
  const navigate = useNavigate();

  const [account, setAccount] = useRecoilState(accountState);

  const referralAddress = useRecoilValue(referralAddressState);

  const [web3Api, setWeb3Api] = useRecoilState(web3ApiState);

  const [isConnecting, setIsConnecting] = useRecoilState(isConnectingState);
  const [error, setError] = useState();
  const [isAvalanche, setIsAvalanche] = useState(false);
  const [referralModalOpened, setReferralModalOpened] = useState(false);
  const [referralMaxLevels, setReferralMaxLevels] = useState(0);
  const [cgsModalOpened, setCgsModalOpened] = useState(false);
  const [disclaimerModalOpened, setDisclaimerModalOpened] = useState(false);
  const [provider, setProvider] = useState(window.ethereum);
  const [purchaseErrorMessage, setPurchaseErrorMessage] = useState("");
  const [waiting, setWaiting] = useState(null);
  const [approving, setApproving] = useState(null);
  const [pendingWaiting, setPendingWaiting] = useState(null);
  const { t, i18n } = useTranslation();
  const [kyc, setKyc] = useRecoilState(kycState);
  const [kycMessage, setKycMessage] = useState("");
  const [networkId, setNetworkId] = useRecoilState(networkIdState);



  const connectWallet = useCallback(async () => {
    try {

      let provider;
      // Loading...
      setIsConnecting(true);
      // Get all the accounts and the user will be able to choose one.
      let wallets;
      try {
        let wallet = localStorage.getItem("wallet");
        if (
          wallet !== undefined &&
          wallet !== "undefined" &&
          wallet !== null &&
          wallet !== "null"
        ) {
          wallets = await onboard.connectWallet({ autoSelect: wallet });
        } else {
          wallets = await onboard.connectWallet();
        }

        if (wallets[0]) {
          setWeb3Api((prevState) => {
            return { ...prevState, isLoading: true, success: false };
          });
          provider = new ethers.providers.Web3Provider(
            wallets[0].provider,
            "any"
          );

          window.onBoardProvider = wallets[0].provider;
          setWeb3Api((prevState) => {
            return {
              ...prevState,
              isLoading: false,
              success: true,
              provider: provider,
            };
          });
          setProvider(provider.provider);
          const accounts = await provider.provider.request({
            method: "eth_requestAccounts",
          });

          // debugger;
          if (
            (localStorage.getItem("loggedOut") == "true" ||
              localStorage.getItem("loggedOut") == null) &&
            localStorage.getItem("token") == null
          ) {
            const signature = await provider.provider.request({
              method: "personal_sign",
              params: [accounts[0], accounts[0]],
            });
            localStorage.setItem(
              "wallet",
              onboard.state.get().wallets[0].label
            );

            await handleRegister(collectionName,accounts[0], signature);
          }

          localStorage.setItem("loggedOut", "false");

          // Loading...
          setIsConnecting(false);
          setAccount(accounts[0]);
          // Test provider avalanche

          if (provider.provider.chainId !== AppParams.AppChainId) {
            await handleNetworkSwitch(AppParams.AppChainId);
            // setIsAvalanche(false)
          } else {
            setIsAvalanche(true);
          }

          localStorage.setItem("wallet", onboard.state.get().wallets[0].label);
          console.log("isAvalanche: ", isAvalanche);

          provider &&
            provider.provider.on("accountsChanged", function (accounts) {
              console.log("accountChanged", accounts);
              setAccount(accounts[0]);
            });
          setNetworkId(provider.provider.chainId);
        } else {
          setIsConnecting(false);
        }
      } catch (error) {
        console.log(error);
        localStorage.setItem("loggedOut", "true");

        setIsConnecting(false);
        return;
      }
    } catch (error) {
      console.log(error);
      localStorage.setItem("loggedOut", "true");
      setIsConnecting(false);
      throw new Error("No ethereum object.");
    }
  }, []);

  const changeNetwork = async ({ networkName, setError }) => {
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            ...networks[networkName],
          },
        ],
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleNetworkSwitch = async (networkName) => {
    setError();
    await changeNetwork({ networkName, setError });
  };

  const verifyReferrals = async () => {
    //const provider = new ethers.providers.Web3Provider(window.ethereum);
    const provider = web3Api.provider;

    const signer = await provider.getSigner();
    const contract = new ethers.Contract(
      AppParams.MINTER_MARKET_ADDRESS,
      MinterMarketplaceAbi,
      signer
    );

    if (account == "") {
      //setIsMetamaskAlertOpened(true);
      return;
    }
    setWaiting(categoryName);
    let REFERRALS_MAX_LEVELS = await contract.REFERRALS_MAX_LEVELS(
      collectionAddress
    );
    setReferralMaxLevels(REFERRALS_MAX_LEVELS);
    let referral = await contract.getReferral(collectionAddress, account);

    //todo: change with getReferral
    if (
      referral === "0x0000000000000000000000000000000000000000" &&
      REFERRALS_MAX_LEVELS > 0
    ) {
      setReferralModalOpened(true);
      return;
    } else {
      buyNft(null);
    }
  };
  const openCGs = async () => {
    //const provider = new ethers.providers.Web3Provider(window.ethereum);
    const provider = web3Api.provider;
    if (!provider) {
      //setIsMetamaskAlertOpened(true);
      return;
    }
    // const signer = provider.getSigner();

    const signer = await provider.getSigner();

    if (provider.chainId !== AppParams.AppChainId) {
      await handleNetworkSwitch(AppParams.AppChainId);
    }

    const valueToSend = ethers.utils.parseUnits(price.toString(), "ether");
    const contractErc20 = new ethers.Contract(paymentToken, ERC20Abi, signer);

    let balanceOf = await contractErc20.balanceOf(account);
    if (parseInt(valueToSend) > parseInt(balanceOf)) {
      setPurchaseErrorMessage(
        t("You do not have the necessary ") +
          paymentTokenSymbol +
          t(" tokens in your balance to continue the purchase.")
      );
      return;
    }
    setDisclaimerModalOpened(true);
    // setCgsModalOpened(true)
    if (account == "") {
      //setIsMetamaskAlertOpened(true);
      return;
    }
    setWaiting(categoryName);
  };

  const openKycMessage = () => {
    switch (kyc) {
      case "attente":
        setKycMessage(t("You have to submit your kyc"));
        break;
      case "adminPending":
        setKycMessage(t("KYC Documents pending for validation "));
        break;

      case "echec":
        setKycMessage(t("KYC failed"));
        break;

      case "valide":
        setKycMessage(
          t("KYC validated in blockpass waiting for validation in whitelist")
        );
        break;
    }
  };

  async function buyNft(referral = null) {
    setWaiting(null);
    if (account == "") {
      //setIsMetamaskAlertOpened(true);
      return;
    }

    //const provider = new ethers.providers.Web3Provider(window.ethereum);
    const provider = web3Api.provider;

    const signer = provider.getSigner();

    const contract = new ethers.Contract(
      AppParams.MINTER_MARKET_ADDRESS,
      MinterMarketplaceAbi,
      signer
    );

    const valueToSend = ethers.utils.parseUnits(price.toString(), "ether");

    const contractErc20 = new ethers.Contract(paymentToken, ERC20Abi, signer);
    let balanceOf = await contractErc20.balanceOf(account);
    if (parseInt(valueToSend) > parseInt(balanceOf)) {
      setPurchaseErrorMessage(
        t("You do not have the necessary ") +
          paymentTokenSymbol +
          t(" tokens in your balance to continue the purchase.")
      );
      return;
    }

    let allowanceRes = await contractErc20.allowance(
      account,
      AppParams.MINTER_MARKET_ADDRESS
    );
    console.log(
      "valueToSend | allowance",
      parseInt(valueToSend),
      parseInt(allowanceRes)
    );
    if (parseInt(valueToSend) > parseInt(allowanceRes)) {
      try {
        setApproving(categoryName);
        const approveTransaction = await contractErc20.approve(
          AppParams.MINTER_MARKET_ADDRESS,
          valueToSend
        );
        await approveTransaction.wait();
        setApproving(null);
      } catch (exApp) {
        setApproving(null);
        return;
      }
    }
    try {
      setPendingWaiting(categoryName);
      let transaction;

      let quantity = 1;
      if (referral === null) {
        transaction = await contract.buyToken(
          collectionIndex,
          categoryName,
          quantity
        );
      } else {
        transaction = await contract.referralBuyToken(
          collectionIndex,
          categoryName,
          quantity,
          referral
        );
      }

      setPendingWaiting(null);
      let temp = [...pendings];
      temp.push({
        txId: transaction.hash,
        categoryName: categoryName,
        finished: false,
      });

      setPendings(temp);

      let txResult = await transaction.wait();
      navigate("/nfts/" + localStorage.getItem("collection"));
    } catch (exPurchase) {
      setPendingWaiting(null);
      console.error(exPurchase);
    }

    // Launch popup if not connected!
    if (!web3Api.provider) {
      //setIsMetamaskAlertOpened(true);
      //   return
      return;
    }
  }
  return (
    <>
      {pendings.filter((el) => el.categoryName === categoryName).length > 0 ||
      approving == categoryName ||
      pendingWaiting == categoryName ? (
        <Button className="buy" variant="primary">
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          {approving == categoryName ? t("Approving...") : t("Pending...")}
        </Button>
      ) : (
        <>
          {/* 
          <Button className='buy' variant='primary' disabled={categoryName == waiting} onClick={() => verifyReferrals()}>
            {categoryName == waiting ? 'Waiting...':'Buy'}
          </Button>
          
       */}
          {
          
          account == '' ? 

          <Button
            onClick={(e) => {
              connectWallet();
            }}
          >
            {t("Connect wallet")}
          </Button>
          :
          
          kyc === "active" || whitelistActivated == false ? (
            <Button
              className="buy"
              variant="primary"
              disabled={categoryName == waiting}
              onClick={() => openCGs()}
            >
              {categoryName == waiting ? t("Waiting...") : t("Buy")}
            </Button>
          ) : (
            <Button
              className="buy"
              variant="primary"
              disabled={categoryName == waiting}
              onClick={() => openKycMessage()}
            >
              {t("Buy")}
            </Button>
          )}
        </>
      )}


      <Modal
        className="Imetamask pend"
        show={purchaseErrorMessage.length > 0}
        onHide={(e) => {
          setPurchaseErrorMessage("");
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <h3>{t("Insufficient funds")}</h3>
          {purchaseErrorMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              setPurchaseErrorMessage("");
            }}
          >
            {t("Ok")}
          </Button>
        </Modal.Footer>
      </Modal>
      <ReferralModal
        opened={referralModalOpened}
        onCancel={() => {
          setReferralModalOpened(false);
          setWaiting(null);
        }}
        onIgnoreAndContinue={() => {
          setReferralModalOpened(false);
          buyNft(null);
        }}
        onUseReferralAndContinue={(referral) => {
          setReferralModalOpened(false);
          buyNft(referral);
        }}
      ></ReferralModal>
      {/* <CGSModal
        collectionName={collectionName}
        isModalOpen={cgsModalOpened}
        onCancel={() => {
          setCgsModalOpened(false);
          setWaiting(null);
        }}
        onOK={() => {
          setCgsModalOpened(false);
          setDisclaimerModalOpened(true);
        }}
      ></CGSModal> */}
      <PurchaseDisclaimer
        collectionName={collectionName}
        isModalOpen={disclaimerModalOpened}
        onCancel={() => {
          setDisclaimerModalOpened(false);
          setWaiting(null);
        }}
        onOK={() => {
          setDisclaimerModalOpened(false);
          AppParams.ActivateReferralModule ? verifyReferrals() : buyNft(null);
        }}
      ></PurchaseDisclaimer>
      <Modal
        className="Imetamask pend"
        show={kycMessage.length > 0}
        onHide={(e) => {
          setKycMessage("");
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <h3>{t("KYC")}</h3>
          {kycMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              setKycMessage("");
              if (kyc === "attente") {
                document.getElementById("blockpass-kyc-connect").click();
              }
            }}
          >
            {t("Ok")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BuyNFTMinterMarketplace;
