import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";



import { AppParams } from "../config";
import { accountState, configurationState, kycState } from "../services/atoms";
import httpService from "../services/httpService";
import { showSpinKycState } from './../services/atoms';
import { kycStatusVersionState } from './../services/wsAtoms';


export default function KycForm(props) {
  const [kyc, setKyc] = useRecoilState(kycState);
  const [kycStatusVersion, setKycStatusVersion] = useRecoilState(kycStatusVersionState)
  const [usernameState , setUsernameState] = useState('')
  const [showSpin , setShowSpin] = useRecoilState(showSpinKycState);
  const { t, i18n } = useTranslation();

  const configuration = useRecoilValue(configurationState)
  const account = useRecoilValue(accountState)


  useEffect(() => {
    getKycStateCurrentUser();
  }, [kycStatusVersion]);


  useEffect(() => {
     const blockpass = null;
    
    if ( account!='' && kyc === 'attente' && configuration.blockPassClientId!= null && configuration.blockPassClientId!=="" ) {
       const blockpass = new window.BlockpassKYCConnect(configuration.blockPassClientId, { env: 'prod', refId: account, mainColor: '030239' });
      console.log("clicked");
      blockpass.startKYCConnect();
      blockpass.on('KYCConnectLoad', () => {
        console.log("KYCConnectLoad")
        //verifieIfUserAccountValidated();
        setShowSpin(false);
      })
      blockpass.on('KYCConnectSuccess', () => {
       setKyc("adminPending");
      });
      blockpass.on('KYCConnectClose', () => {
        //checkingKYC();
      });
    }
    return () => {
      if (blockpass) blockpass.stopKYCConnect();
    }
  }, [kyc,account]);

  const getKycStateCurrentUser = async () => {
    //call server
    httpService
      .get(`${AppParams.BACKEND_URL}/users/kyc-status`)
      .then((response) => {
        const data = response.data;
        setKyc(data.kycState);
        setUsernameState(data.username)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStartKyc = ()=>{
    setShowSpin(true);
  }

  const renderSwitchState = () => {""

    

    switch (kyc) {
      case "attente":
        return (
          <div className="kyc">
            <span>{t("Submit your KYC Documents")}</span>
            <small>
            {t("Submit your documents to increase account functionalities ")}
              {/* <a>Start now.</a> */}
              <a style={{ cursor: 'pointer' }} id="blockpass-kyc-connect" onClick={handleStartKyc}>{t("Start now")}</a>
            </small>
          </div>
        );
        
        case "adminPending":
          return (
            <div className="kyc">
              <span>{t("KYC Documents pending for validation ")}</span>
            </div>
          );
        case "echec":
          return (
            <div className="kyc">
              <span>{t("KYC failed")}</span>
            </div>
          );
           
        case "valide":
            return (
              <div className="kyc">
                <span>{t("KYC validated in blockpass waiting for validation in whitelist")}</span>
              </div>
            );
         


      case "active":
        return (
          <div></div>
        );
       

      default:
        return (
        <div></div>
        );
    }
  };

  return <React.Fragment>{renderSwitchState()}</React.Fragment>;
}
