import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

import { ethers } from 'ethers'
import axios from 'axios'

import {
  AppParams,
  TokensClient,
} from '../config'

import BuyNFT from '../components/BuyNFT'
import { myTokenDetails } from '../services/graphql'
import { useRecoilState } from 'recoil'
import { referralReductionState } from '../services/atoms'
import { useTranslation } from 'react-i18next'

const NFTCard = ({ nft,collectionName }) => {


  const [referralReduction, setReferralReduction] = useRecoilState(referralReductionState)
  const { t, i18n } = useTranslation();

  const [item, setItem] = useState({
    tokenId: <hr size='5' color='#7373ab' width='40' />,
    image: '/images/Spin-det.svg',
    name: <hr size='5' color='#7373ab' width='70' />,
    description: <hr size='10' color='#7373ab' width='90' />,
  })


  useEffect(() => {
    //loadNFTs()
  }, [])
  useEffect(async () => {
    TokensClient.query({
      query: myTokenDetails,
      fetchPolicy: 'network-only',
      variables: {
        contractAddress: nft.tokenContract.id,
        tokenId: parseInt(nft.tokenId),
      },
    }).then((res) => {
      console.log("res.data.nfts[0]",res.data.nfts[0])
      axios.get(res.data.nfts[0].tokenURI.replace("ipfs://", AppParams.IPFS_GATWAY)).then((meta) => {
        let tokensMeta = {
          image: meta.data.image.replace("ipfs://", AppParams.IPFS_GATWAY),
          name: meta.data.name,
          title: meta.data.title,
          titleFr: meta.data.titleFr,
          descriptionEn: meta.data.descriptionEn,
          descriptionFr: meta.data.descriptionFr,
          owner: res.data.nfts[0].currentOwner.id,
          expirable: res.data.nfts[0].expirable,
          expireIn: res.data.nfts[0].expireIn,
          multiplier:
            res.data.nfts[0].multiplier > 0
              ? res.data.nfts[0].multiplier / 10 ** 6
              : 0,
          tokenYield:
            res.data.nfts[0].tokenYield > 0
              ? ethers.utils.formatUnits(res.data.nfts[0].tokenYield, 'ether')
              : 0,
          //rate: res.data.nfts[0].rate > 0 ? res.data.nfts[0].rate / 10 ** 6 : 0,
          rate: 0,
          useNumber: res.data.nfts[0].useNumber != null ? res.data.nfts[0].useNumber : 0,

        }
        setItem(tokensMeta)
      })
    })
  }, [nft.itemId])

  function showOnExplorer(event) {
    event.stopPropagation()
    window.open(
      `${AppParams.BLOCK_EXPLORER_URL}token/${nft.tokenContract.id}?a=${nft.tokenId}`,
      '_blanc'
    )
  }

  // parse float
  function circumference(r) {
    if (Number.isNaN(Number.parseFloat(r))) {
      return 0
    }
    return parseFloat(r)
  }

  return (
    <Card className='my-3 nftC'>
      <Card.Body>
        <div style={{ display: 'flex' }}>
          <div>
            <span className='CardTokenId' onClick={showOnExplorer}>
              #{nft.tokenId}
            </span>
          </div>
          <div className="CardTokenTokenClass">
            <div className='codeD'>{nft.tokenClass.name}</div>
          </div>
        </div>
        <div className='titleN' style={{ paddingTop: 10 }}>
          <Link to={`/nft/${nft.tokenId}/${nft.itemId}/${nft.tokenContract.id}`}>
            <Card.Title as='div'>
              <span>{item.name}</span>
            </Card.Title>
          </Link>
        </div>

        <Card.Text as='div' className='desc'>
          {nft.description}
        </Card.Text>
        <Link to={`/nft/${nft.tokenId}/${nft.itemId}/${nft.tokenContract.id}`}>
          <Card.Img variant='top' src={item.image} />
        </Link>
        <div className='positionDe'>
            <Card.Text className='CardTokenDescription'>
              {i18n.language == 'fr' ? item?.titleFr : item?.title}
            </Card.Text>
          <div className='AlignBc'>

            <Card.Text as='h3'>
              {circumference(nft.tokenClass.price * (1-referralReduction/100)).toLocaleString().replace(",", ".")}{' '}{AppParams.PAYEMENT_TOKEN_NAME}
            </Card.Text>



          </div>
          <div className='AlignBc'>
            <BuyNFT
              props={{
                nftContract: nft.tokenContract.id,
                itemId: nft.itemId,
                price: nft.tokenClass.price,
                avaxPayement: false,
                collection:collectionName,
                paymentToken:AppParams.PAYEMENT_TOKEN_CONTRACT_ADDRESS
              }}
            ></BuyNFT>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default NFTCard
