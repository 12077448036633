import axios from "axios";
import { AppParams } from "../config";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.error("An unexpected error occurrred.");
  }

  if (error.response.status == "401") {
    setJwt(null);
  }

  return Promise.reject(error);
});

axios.interceptors.request.use(
  (config) => {
    //console.log(config)
    if (getJwt() != null && config.url.includes(AppParams.BACKEND_URL)) {
      config.headers["Authorization"] = `Bearer ${getJwt()}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function setJwt(token) {
  if (token == null) {
    localStorage.removeItem("token");
  } else {
    localStorage.setItem("token", token);
  }

  const jwt = getJwt();

  console.log("tokk : ", jwt);
  //axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
}

function getJwt() {
  return localStorage.getItem("token");
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  getJwt,
};
