import React, { useEffect, useState } from "react";
import {
  accountState,
  pendingTransactions,
  purchaseEnd,
  refreshIndex,
} from "../services/atoms";
import { useRecoilState, useRecoilValue } from "recoil";

import { Badge, Button, ListGroup, Modal, Spinner } from "react-bootstrap";
import { shortenTxId } from "../utils/shortenAddress";
import {
  AppParams,
  MarketPlaceClient,
  MinterMarketPlaceClient,
} from "../config";
import {
  marketplaceItemById,
  mintSaleTransactionDetails,
} from "../services/graphql";

import { useInterval } from "usehooks-ts";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotificationBar = () => {
  const [pendings, setPendings] = useRecoilState(pendingTransactions);
  const account = useRecoilValue(accountState);
  const [modalPendingOpened, setModalPendingOpened] = useState(false);
  const [modalTokenPurchasedOpened, setModalTokenPurchasedOpened] =
    useRecoilState(purchaseEnd);
  const [refresh, setRefresh] = useRecoilState(refreshIndex);
  const [validatedTokenPurchase, setValidatedTokenPurchase] = useState({
    tokenId: "",
    contract: "",
    categoryName:""
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let pendingString = localStorage.getItem("pendingTransactions");
    if (pendingString != null) {
      let temp = JSON.parse(pendingString);
      setPendings(temp);
      //for(var i = 0; i<temp.le)
      /*temp.push({
                txId: "temp.hash",
                itemId: 'itemId',
                finished: 'false'
            })*/

      //setPendings(temp);
    }
  }, []);

  useInterval(() => {
    verifyElements();
  }, 5000);

  useEffect(() => {
    let pendingString = JSON.stringify(pendings);
    localStorage.setItem("pendingTransactions", pendingString);
  }, [pendings]);

  const handleViewMyAsset = () => {
    navigate(`/my-assets/${localStorage.getItem("collection")}`);
    setModalTokenPurchasedOpened(false);
  };

  const verifyElements = () => {
    //console.log("verifyElement pendings",pendings)
    pendings.map((element) =>
      element.itemId
        ? verifyElement(element.itemId)
        : verifyMintingElement(element.txId)
    );
  };

  const verifyElement = (itemId) => {
    
    //console.log("verifyElement",itemId)
    return MarketPlaceClient.query({
      query: marketplaceItemById,
      fetchPolicy: "network-only",
      variables: {
        itemId: parseInt(itemId),
      },
    }).then((res) => {
      
      let availableForSale = res.data.nfts[0].availableForSale;

      if (availableForSale == false) {
        setValidatedTokenPurchase({
          tokenId: res.data.nfts[0].tokenId,
          contract: res.data.nfts[0].tokenContract.id,
          categoryName: res.data.nfts[0].tokenClass.name
        });
        setModalTokenPurchasedOpened(true);
        let items = pendings.filter((el) => el.itemId !== itemId);
        setPendings(items);
        setRefresh(refresh + 1);
      }
      console.log({ data: availableForSale });
    });
  };
  const verifyMintingElement = (txId) => {
    return MinterMarketPlaceClient.query({
      query: mintSaleTransactionDetails,
      fetchPolicy: "network-only",
      variables: {
        transactionId: txId,
      },
    }).then((res) => {
      //console.log("verifyElement",res)
      //let availableForSale = res.data.nfts[0].availableForSale
      if (res.data.saleTransactions.length > 0) {
        setValidatedTokenPurchase({
          tokenName: res.data.saleTransactions[0].tokenClass.tokenContract.name,
          contract: res.data.saleTransactions[0].tokenClass.tokenContract.id,
          categoryName: res.data.saleTransactions[0].tokenClass.name,
        });
        setModalTokenPurchasedOpened(true);
        let items = pendings.filter(
          (el) =>
            el.categoryName !== res.data.saleTransactions[0].tokenClass.name
        );
        setPendings(items);
        setRefresh(refresh + 1);
      }
      //console.log({ data: availableForSale })
    });
  };
  const navigate = useNavigate();
  console.log(validatedTokenPurchase);
  /*Modal*/
  const showItem = (pending) => {
    return (
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
        key={pending.txId}
      >
        <div className="ms-2 me-auto d-f-l">
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <div>{t("ITEM ID :")} </div>
            <div> {pending.itemId}</div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <div>{t("TX ID :")} </div>
            <div className="adresst">
              {" "}
              <a
                style={{ color: "#baff85" }}
                href={AppParams.BLOCK_EXPLORER_URL + "tx/" + pending.txId}
                target="_blank"
                rel="noreferrer"
              >
                {shortenTxId(pending.txId)}
              </a>
            </div>
          </div>
        </div>
        {/*<Badge bg="primary" pill>
                    14
                </Badge>*/}
      </ListGroup.Item>
    );
  };

  return (
    <div>
      {pendings.length > 0 ? (
        <div className="btnPending">
          <Spinner animation="border" role="status"></Spinner>
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              setModalPendingOpened(true);
            }}
          >
            {pendings.length} {t("Pending transactions")}
          </span>
        </div>
      ) : null}

      <Modal
        show={modalPendingOpened}
        onHide={(e) => {
          setModalPendingOpened(false);
        }}
        backdrop="static"
        keyboard={false}
        className="pend"
      >
        <Modal.Header></Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          {pendings.length > 0 ? (
            <ListGroup as="ol">
              {pendings.map((pending) => showItem(pending))}
            </ListGroup>
          ) : (
            <span>{t("No pending transaction")}</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setModalPendingOpened(false);
            }}
          >
            {t("Ok")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalTokenPurchasedOpened}
        onHide={(e) => {
          setModalTokenPurchasedOpened(false);
        }}
        backdrop="static"
        keyboard={false}
        className="pR0"
      >
        <Modal.Header style={{ justifyContent: "flex-end" }}>
          <span
            variant="secondary"
            onClick={(e) => {
              setModalTokenPurchasedOpened(false);
            }}
            style={{ fontSize: 20, cursor: "pointer" }}
          >
            x
          </span>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <h4 style={{ textAlign: "center", marginBottom: 35, color: "#fff" }}>
            {t("Asset added to your collection")}
          </h4>
          {t(
            `NFT purchase is completed. Check your asset details`
          ).replace("{tokenName}",validatedTokenPurchase.categoryName)}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          {/*<Button variant="secondary" onClick={(e) => { setModalTokenPurchasedOpened(false) }}>
                        Ok
                    </Button> */}
          <Button onClick={handleViewMyAsset}>{t("Here")}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NotificationBar;
