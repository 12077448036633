import { ethers } from 'ethers';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';

function EditCategories({ onValuesChanged = null, withCalegoriesURIs }) {
    const [data, setData] = useState([
    ]);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [categoryInput, setCategoryInput] = useState('');
    const [priceInput, setPriceInput] = useState('');
    const [categoriesURI, setCategoriesURI] = useState('');



    useEffect(() => {
        if (onValuesChanged == null) return;
        const categories = data.map(el => el.category)
        const categoriesURIs = data.map(el => el.categoriesURI)
        const prices = data.map(el => ethers.utils.parseUnits(el.price.toString(), 'ether'))
        console.log(categories)
        console.log(prices)
        onValuesChanged({
            categories: categories,
            prices: prices,
            categoriesURI: categoriesURIs
        });
    }, [data])

    const handleAdd = () => {
        setData([...data, { category: categoryInput, price: parseFloat(priceInput), categoriesURI: categoriesURI }]);
        setCategoryInput('');
        setPriceInput('');
        setCategoriesURI('');
    }

    const handleEdit = (index) => {
        setEditingIndex(index);
        setCategoryInput(data[index].category);
        setPriceInput(data[index].price.toString());
        setCategoriesURI(data[index].categoriesURI);
    }

    const handleSave = () => {
        const newData = [...data];
        newData[editingIndex] = { category: categoryInput, price: parseFloat(priceInput), categoriesURI: categoriesURI };
        setData(newData);
        setEditingIndex(-1);
        setCategoryInput('');
        setPriceInput('');
        setCategoriesURI('');
    }

    const handleDelete = (index) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    }

    return (

        <>
            <label>Catégories</label>
            <table>
                <thead style={{ backgroundColor: "#1976d2" }}>
                    <tr>
                        <th>Category</th>
                        <th>Price</th>
                        {
                            withCalegoriesURIs && <th>Metas CID</th>
                        }
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td>{editingIndex === index ? (
                                <input type="text" value={categoryInput} onChange={(e) => setCategoryInput(e.target.value)} />
                            ) : (
                                row.category
                            )}</td>
                            <td>{editingIndex === index ? (
                                <input type="number" value={priceInput} onChange={(e) => setPriceInput(e.target.value)} />
                            ) : (
                                row.price
                            )}</td>
                            {
                                withCalegoriesURIs ? (

                                    <td>{editingIndex === index ? (
                                        <input type="text" value={categoriesURI} onChange={(e) => setCategoriesURI(e.target.value)} />
                                    ) : (
                                        row.categoriesURI
                                    )}</td>
                                ) : null
                            }
                            <td>
                                {editingIndex === index ? (
                                    <>
                                        <Button onClick={handleSave}>Save</Button>
                                        <Button onClick={() => setEditingIndex(-1)}>Cancel</Button>
                                    </>
                                ) : (
                                    <>
                                        <Button onClick={() => handleEdit(index)}>Edit</Button>
                                        <Button onClick={() => handleDelete(index)}>Delete</Button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    {
                        editingIndex === -1 ? (
                            <tr>
                                <td><input type="text" value={categoryInput} onChange={(e) => setCategoryInput(e.target.value)} /></td>
                                <td><input type="number" value={priceInput} onChange={(e) => setPriceInput(e.target.value)} /></td>
                                {
                                    withCalegoriesURIs ? (
                                        <td><input type="text" value={categoriesURI} onChange={(e) => setCategoriesURI(e.target.value)} /></td>
                                    ) : null
                                }
                                <td><Button onClick={handleAdd}>Add</Button></td>
                            </tr>) : (null)
                    }
                </tfoot>
            </table>
        </>
    );
}

export default EditCategories;
