import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';


export default function LangSelector(props) {


    const { t, i18n } = useTranslation();

    const [selectedLang, setSelectedLang] = useState(props.supportedLangs[0]);
    const [notSeletedLang, setNotSelectedLang] = useState([])

    useEffect(() => {
        if (props.supportedLangs.length == 0) return;

        props.supportedLangs.forEach(element => {
            if (element.name == i18n.language) {
                setSelectedLang(element)
            }
        });
    }, [props.supportedLangs, i18n.language])

    useEffect(() => {
        if (props.supportedLangs.length == 0) return;

        let notSelected = [];
        props.supportedLangs.forEach(element => {
            if (element.name != i18n.language) {
                notSelected.push(element)
            }
        });
        setNotSelectedLang(notSelected);
    }, [props.supportedLangs, selectedLang])


    const changeLang = (lang) => (e) => {
        e.preventDefault();
        i18n.changeLanguage(lang.name);
        localStorage.setItem("lang", lang.name);
        setSelectedLang(lang)
        if(props.afterLangChange != undefined){
            props.afterLangChange(lang.name);
        }
        return false;
    };

    const getOtherLangElements = () => {
        if (notSeletedLang.length == 0) return;
        var elements = [];
        notSeletedLang.forEach(element => {
            elements.push(<img key={element.name} onClick={changeLang(element)} src={element.image} width="20"></img>)
        });
        return elements;
    }

    return (
        
       
        <div style={{display:'flex',gap:10,marginLeft:5,cursor:'pointer'}}>{getOtherLangElements()}</div>
        
    )
}
