import { gql } from "apollo-boost";


export const getStakingHoldersList = gql`
query stakingHoldersList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!){
  users(first: $limit, skip: $offset , orderBy: $orderBy,orderDirection: $orderDirection) {
    id
    numberTokensStaked
    poolParticipation
  }
  contracts{
    usersCount
    totalPoolValue
  }
        
}`


export const itemsForSale = gql`
query itemsList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!,$filter:String!,$minPrice:String!,$maxPrice:String!,$category:String!,$collection:String!){
  nfts(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection,
  where: {
    currentSeller_contains: $filter,
    availableForSale:true,
      tokenClass_ : { 
        price_gte:$minPrice,
        price_lte:$maxPrice
     },
     tokenContract_ : {
        id:$collection
     } 
  }
  ) {
    id
    tokenId
    itemId
    tokenURI
    tokenContract {
      id
      numberTokensListed
      name
      whitelist
    }
    updatedAt
    currentSeller
    currentOwner{
      id
    }
    availableForSale
    tokenClass {
      id
      price
      name
    }
  }
}
`
export const itemsForSaleByCategory = gql`
query itemsList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!,$filter:String!,$minPrice:String!,$maxPrice:String!,$category:String!,$collection:String!){
  nfts(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection,
  where: {
    tokenClass:$category,
    currentSeller_contains: $filter,
    availableForSale:true,
      tokenClass_ : { 
        price_gte:$minPrice,
        price_lte:$maxPrice
     },
     tokenContract_ : {
        id:$collection
     } 
  }
  ) {
    id
    tokenId
    itemId
    tokenURI
    tokenContract {
      id
      numberTokensListed
      name
      whitelist
    }
    updatedAt
    currentSeller
    currentOwner{
      id
    }
    availableForSale
    tokenClass{
      id
      price
      name
    }
  }
}
`

export const marketplaceContractInfos = gql`
 query contractList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!){
  contracts(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection) {
    id
    name
    symbol
    tradesNumber
    numberTokensListed
  }
}
`
export const listedCollections = gql`
 query contractList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!){
  alls(first: 1) {
    id
    numTokenContracts
    numTokens
    numOwners
  }
  tokenContracts(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection) {
    id
    name
    isMinterMarketplace
    numTokens
  }
}
`

export const myTokens = gql`
query itemsList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!,$filter:String!,$category:String!,$account:String!){
  nfts(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection,where: {
    currentOwner :{id_contains_nocase:$account},
  }) {
    id
    tokenId
    tokenURI
    tokenClass
    tokenContract {
      id
      name
    }
    owner {
      id
    }
  }
}
`
export const myTokensByCategory = gql`
query itemsList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!,$filter:String!,$category:String!,$account:String!){
  nfts(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection,where: {
    tokenClass:$category,
    owner_contains_nocase:$account
  }) {
    id
    tokenId
    tokenURI
    tokenClass
    tokenContract {
      id
      name
    }
    owner {
      id
    }
  }
}
`
export const myTokensByCollection = gql`
query itemsList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!,$filter:String!,$category:String!,$account:String!){
  nfts(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection,where: {
    currentOwner_ :{id:$account},
    tokenContract_: { 
      name:$filter
     }
  }) {
    id
    tokenId
    tokenURI
    tokenClass{
      id
      name
    }
    tokenContract {
      id
      name
    }
    currentOwner {
      id
    }
  }
}
`
export const myTokensCategoryContains = gql`
query itemsList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!,$filter:String!,$category:String!,$account:String!){
  nfts(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection,where: {
    tokenClass_contains:$category,
    owner_contains_nocase:$account
  }) {
    id
    tokenId
    tokenURI
    tokenClass
    contract {
      id
      name
    }
    owner {
      id
    }
  }
}
`
export const myTokenDetails = gql`
query tokenDetails($tokenId:Int!,$contractAddress:String!){
  nfts(first: 1, skip: 0,where: {
    tokenId:$tokenId,
    tokenContract:$contractAddress
  }) {
    id
    tokenId
    tokenURI
    tokenClass{
      id
      name
    }
    tokenContract {
      id
      name
    }
    currentOwner {
      id
    }
    currentSeller
  }
}
`

export const marketplaceItemById = gql`
query marketplaceItemById($itemId:Int!){
  nfts(first: 1, skip: 0,where: {
    itemId:$itemId
  }) {
    id
    tokenId
    itemId
    tokenURI
    tokenContract {
      id
      name
      whitelist
    }
    updatedAt
    currentSeller
    currentOwner{
      id
    }
    availableForSale
    tokenClass{
      id
      price
      name
    }
  }
}
`

export const tokenCategoriesList = gql`
 query myTokenClasses($collection:String!){
  tokenClasses(first:999,where: {
    tokenContract_ : { 
      id:$collection
     } 
  }){
    id
    numberTokensListed
    name
    price
    tokenContract{
      id
    }
  }
}
`
export const tokenCategoriesListById = gql`
 query tokenCategoriesListById($collection:String!){
  tokenClasses(first:999,where: {
    tokenContract_ : { 
      id:$collection
     } 
  }){
    id
    numberTokensListed
    name
    price
    tokenContract{
      id
    }
  }
}
`

export const collectionDetails = gql`
 query mycollectionDetails($collection:String!){
  tokenContracts(first:1,where:{name:$collection}){
    id
    name
    numTokens
    collectionURI
    isMinterMarketplace
    whitelist
    paymentToken
    paymentTokenName
    paymentTokenSymbol
  }
}
`
export const collectionDetailsById = gql`
 query collectionDetailsById($collection:String!){
  tokenContracts(first:1,where:{id:$collection}){
    id
    name
    numTokens
    collectionURI
    isMinterMarketplace
  }
}
`
export const levelRewardRates = gql`
 query myLevelRewardRates($collectionAddress:String!){
  levelRewardRates(first:50,where:{tokenContract_:{id:$collectionAddress}}){
    id
    rewardRate
    tokenContract{
      name
    }
  }
}
`
export const mintSaleTransactionDetails = gql`
 query myMintSaleTransactionDetails($transactionId:String!){
  saleTransactions(first:1,where:{id:$transactionId}) {
    id
    tokenClass{
      id
      name
      tokenContract{
        id
        name
      }
    }
  }
}

`


export const listedCollectionsMinterMarketPlace = gql`
 query myTokenClasses($collection:String!){
  tokenClasses(first:999,orderBy:"price",where: {
    tokenContract_ : { 
      id:$collection
     } 
  }){
    id
  	name  
    categoryURI
    saleOpened
    price
    totalSales
    paymentToken
    paymentTokenName
    paymentTokenSymbol
    tokenContract{
      id
      name
      marketPlaceIndex
      whitelist,
    }
  }
}
`
export const categoryDetailsMinterMarketPlace = gql`
 query myTokenClasses($collectionAddress:String!,$category:String!){
  tokenClasses(first:999,where: {
    name:$category
    tokenContract_ : { 
      id:$collectionAddress
     } 
  }){
    id
  	name  
    categoryURI
    saleOpened
    price
    totalSales
    paymentToken
    paymentTokenName
    paymentTokenSymbol
    tokenContract{
      id
      name
      marketPlaceIndex
      seller
      collectionURI
      whitelist
    }
  }
}
`

/*
export const referralsList = gql`
 query referralsList($collection:String!,$publicAddress:String!){
  users(where:{
    tokenContract_:{
      id:$collection
    }
    publicAddress:$publicAddress
    }){
    publicAddress
    childs{
      publicAddress
      childs{
				publicAddress
      }
    }
  }
}
`*/
export const referralsList = gql`
 query referralsList($parentId:String!){
  referralsDistributions(limit:999,where:{
    parent_:{
      id:$parentId
    }
  }){
    id
    parent{
      publicAddress
      referralHierarchySize
      totalCollectedReferralreward
    }
    child{
      publicAddress
      parent{
        publicAddress
      }
    }
    level
    totalDistributedAmount
  }
}
`