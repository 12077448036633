import React, { forwardRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
//import Spinner from 'react-spinner-material';
import { AppParams, MarketPlaceClient, StakingClient, TokensClient } from "../config";
import { getStakingHoldersList, listedCollections, marketplaceContractInfos } from "../services/graphql";
import { Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useNavigate } from 'react-router-dom'


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const tableColumnConfig = [
    {
        title: 'Token name',
        field: 'name',
        render: row => {
            return <div className="table-cell">
                <div className="tx-field-tables" data-toggle="tooltip" data-placement="top" titre={row.name} >
                    <Link to={`/nfts/${row.name}`}>
                        {row.name}
                    </Link>
                </div>
            </div>;
        }
    },
    {
        title: 'Address',
        field: 'id',
        render: row => {
            let m = row.id && row.id.match && row.id.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
            return <div className="table-cell"><div className="tx-field-tables" data-toggle="tooltip" data-placement="top" titre={row.id} ><a target="_blank" rel="noopener noreferrer" href={`${AppParams.BLOCK_EXPLORER_URL}address/${row.id}`}>{m ? m[1] + '...' + m[3] : ''}</a></div></div>;
        }
    },
    {
        title: 'Total tokens',
        field: 'numTokens',
        render: (row) => {
            return <div className="table-cell">{row.numTokens}</div>
        }

    },
    {
        title: 'Mint mode',
        field: 'isMinterMarketplace',
        render: (row) => {
            return <div className="table-cell">{row.isMinterMarketplace ? 'On sale' : 'Manual'}</div>
        }

    },
    {
        title: 'Actions',
        field: 'name',
        render: (row) => {
            if (!row.isMinterMarketplace)
                return (
                    <div>
                        <Link className="Link-element" to={`/mint-tokens/${row.id}`}>
                            Mint
                        </Link>
                        <Link className="Link-element" to={`/manage-collection/${row.id}/${AppParams.MARKET_ADDRESS}`}>
                            Manage
                        </Link>
                    </div>
                )
            else
                return (
                    <div>
                        <Link className="Link-element" to={`/manage-collection/${row.id}/${AppParams.MINTER_MARKET_ADDRESS}`}>
                            Manage
                        </Link>
                    </div>)
                    ;
        }

    }
]

const remoteData = (query) => {
    console.log("Query object - ", query)

    return TokensClient.query({
        query: listedCollections,
        variables: {
            offset: query.page * query.pageSize,
            limit: query.pageSize,
            orderBy: query.orderBy == undefined || query.orderBy.field == "id" ? "id" : query.orderBy.field,
            orderDirection: query.orderDirection == undefined || query.orderDirection == "" ? "desc" : query.orderDirection
        }
    }).then((res) => {
        console.log(res)
        /*for (var i = 0; i < res.data.tokenContracts.length; i++) {
            res.data.tokenContracts[i].poolParticipationRate = (res.data.tokenContracts[i].poolParticipation / res.data.tokenContracts[0].totalPoolValue) * 100
        }*/
        setTimeout(() => {
            //window.$('div[data-toggle]').tooltip({});
        }, 1000);
        return {
            data: res.data.tokenContracts,
            page: query.page,
            totalCount: res.data.alls[0].numTokenContracts === undefined ? 0 : (parseInt(res.data.alls[0].numTokenContracts))
            //totalCount: 100
        }
    })
}

const ManageApp = (props) => {
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setVisible(true);
        }, 1000);
    }, [])

    return (
        <>

            <div className="textCenter textM"><h2 className="w-700">App <span style={{ color: "rgb(109, 255, 167)" }}>List</span></h2></div>

            <Button onClick={() => { navigate("/deploy-nft-contract") }}>Deploy new APP</Button>

            <div className=" row ANTRectangleTS mb-5 mt-5" style={{ paddingBottom: 0 }}>

                <div style={{ minHeight: 300 }} className="tabalance">
                    {
                        visible ? (
                            <MaterialTable
                                icons={tableIcons}
                                columns={tableColumnConfig}
                                data={remoteData}
                                options={{
                                    toolbar: false,
                                    showFirstLastPageButtons: false,
                                }}

                            />
                        ) : null
                    }
                </div>

            </div>
        </>
    )

}
export default ManageApp;