import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Row, Col, Image, ListGroup, Card, Button } from 'react-bootstrap'
// import Rating from '../components/Rating'
//import products from '../products'
import axios from 'axios'

import {
  AppParams,
  MarketPlaceClient,
  TokensClient
} from '../config'

import { MarketplaceAbi } from '../artifacts/contracts-abis/Marketplace-abi'
import BuyNFT from '../components/BuyNFT'
import { marketplaceItemById, myTokenDetails } from '../services/graphql'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { accountState, collectionNameState, configurationState, referralReductionState, refreshIndex, whitelistActivatedState } from '../services/atoms'
import httpService from '../services/httpService'
import SwitchDesign from '../components/SwitchDesign'
import { ethers } from 'ethers'

const NFTDetails = () => {
  const { token_id, item_id, contractAddress } = useParams()
  const [nft, setNft] = useState([])
  const { t, i18n } = useTranslation();

  const [referralReduction, setReferralReduction] = useRecoilState(referralReductionState)
  const [configuration, setConfiguration] = useRecoilState(configurationState)
  const [whitelistActivated, setWhitelistActivated] = useRecoilState(whitelistActivatedState)

  const [account, setAccount] = useRecoilState(accountState)
  const [refresh, setRefresh] = useRecoilState(refreshIndex);

  const [collectionName, setCollectionName] = useRecoilState(collectionNameState)

  const [item, setItem] = useState({
    image: '/images/Spin-det.svg',
    name: '--',
    category: '--',
    tokenURI: '--'
  })


  useEffect(async () => {

    if (account == '' || !contractAddress) {
      setReferralReduction(0)
      return;
    } else {

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const contractMarketplace = new ethers.Contract(
        AppParams.MARKET_ADDRESS,
        MarketplaceAbi,
        signer
      );
      
      let referrals = await contractMarketplace.getReferralsList(contractAddress, account);
      if (referrals[0].account != "0x0000000000000000000000000000000000000000") {
        let reduction = parseInt(await contractMarketplace.referralLevelReward(contractAddress, 0));
        setReferralReduction(reduction / 10 ** 6)

      } else {
        setReferralReduction(0)
      }
    }
  }, [contractAddress, account, refresh])


  useEffect(() => {
    // Load NFTS
    loadNFTs()
  }, [])

  useEffect(() => {
    if (collectionName == null) return;

    if (localStorage.getItem("collection") == null || localStorage.getItem("collection") == "" || localStorage.getItem("collection") == "null") {
      localStorage.setItem("collection", collectionName)
    }

    httpService.get(
      `${AppParams.BACKEND_URL}/config/projects?projectID=${collectionName}`
      ,
    )
      .then(response => {
        setConfiguration(response.data)
      })
      .catch(error => {
        console.log(error)
      });
  }, [collectionName])

  async function loadNFTs() {

    MarketPlaceClient.query({
      query: marketplaceItemById,
      fetchPolicy: 'network-only',
      variables: {
        itemId: parseInt(item_id),
      },
    }).then((res) => {
      let item = {
        price: res.data.nfts[0].tokenClass.price,
        itemId: res.data.nfts[0].itemId,
        seller: res.data.nfts[0].currentSeller,
        owner: res.data.nfts[0].currentSeller,
        collection: res.data.nfts[0].tokenContract.name
      }
      if (res.data.nfts[0].tokenContract.whitelist != "0x0000000000000000000000000000000000000000") {
        setWhitelistActivated(true)
      }else{
        setWhitelistActivated(false)
      }
      
      setNft(item)
    })





    TokensClient.query({
      query: myTokenDetails,
      fetchPolicy: 'network-only',
      variables: {
        contractAddress: contractAddress,
        tokenId: parseInt(token_id),
      },
    }).then((res) => {
      axios.get(res.data.nfts[0].tokenURI.replace("ipfs://", AppParams.IPFS_GATWAY)).then((meta) => {
        let tokensMeta = {
          image: meta.data.image.replace("ipfs://", AppParams.IPFS_GATWAY),
          name: meta.data.name,
          descriptionFr: meta.data.descriptionFr,
          descriptionEn: meta.data.descriptionEn,
          owner: res.data.nfts[0].currentOwner.id,
          category: res.data.nfts[0].tokenClass.name,
          tokenURI: res.data.nfts[0].tokenURI,
          //pdfFile: meta.data.pdfFile != null ? meta.data.pdfFile : "--",
        }
        setItem(tokensMeta)
        setCollectionName(res.data.nfts[0].tokenContract.name)
      })
    })
  }

  function showOnExplorer(event) {
    event.stopPropagation()
    window.open(
      `${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${token_id}`,
      '_blanc'
    )
  }

  function circumference(r) {
    if (Number.isNaN(Number.parseFloat(r))) {
      return 0
    }
    return parseFloat(r)
  }

  function downloadURI(uri, tokenClass) {

    fetch(uri)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = tokenClass + '.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })

  }

  return (
    <>
      <SwitchDesign collectionName={collectionName} ></SwitchDesign>

      <div className='screenD container'>
        <Link className='btn btn-outline-primary my-2 pl-0' to={'/nfts/' + collectionName}>
          <i className='fa-solid fa-angle-left'></i> {t("Back")}
        </Link>
        <Row>
          <Col>
            <span
              className='codeD'
              style={{ cursor: 'pointer' }}
              onClick={showOnExplorer}
            >
              #{token_id}
            </span>
          </Col>
        </Row>
        <Card.Text as='h3'>{nft?.name}</Card.Text>
        <Row className="rowD">
          <Col md={6}>
            {/* <Card.Text as='h3'>{nft?.itemId}</Card.Text> */}

            <div className='p25'>
              <Image
                src={item?.image}
                alt={item?.name}
                style={{ borderRadius: 20 }}
                fluid
              />
            </div>



          </Col>
          <Col md={6} className="colS">

            <div>
              <h4>{t("Price")}</h4>
              <ListGroup variant='flush' className='card cardab'>
                <ListGroup.Item>
                  <Row>
                    <Col>
                      <div className='priceD'>
                        <div>
                          {nft.price != null ?
                            referralReduction > 0 ?
                              <h4>
                                <div>
                                  <span className='old-price'>
                                    {circumference(nft.price).toLocaleString().replace(",", ".")}{' '} {AppParams.PAYEMENT_TOKEN_NAME}
                                  </span>
                                  <span>
                                    {" (-" + referralReduction + '% OFF)'}
                                  </span>
                                </div>
                                {circumference(nft.price * (1-referralReduction/100)).toLocaleString().replace(",", ".")}{' '}{AppParams.PAYEMENT_TOKEN_NAME}
                              </h4>
                              :
                              <h4>
                                {circumference(nft.price).toLocaleString().replace(",", ".")}{' '} {AppParams.PAYEMENT_TOKEN_NAME}
                              </h4>
                            :
                            <h4>--</h4>
                          }
                        </div>
                        <BuyNFT
                          props={{
                            nftContract: contractAddress,
                            itemId: item_id,
                            price: nft.price,
                            avaxPayement: false,
                            collection: collectionName,
                            paymentToken: AppParams.PAYEMENT_TOKEN_CONTRACT_ADDRESS
                          }}
                        ></BuyNFT>
                      </div>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div>
              <h4>{t("About")}</h4>
              <ListGroup variant='flush' className='card cardab'>
                <ListGroup.Item className="priceD">
                  {t("Contract")} <a href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}`} target="_blank" className="itemS"><span style={{ color: '#BAFF85 !important' }}>{contractAddress}</span></a>
                </ListGroup.Item>
                <ListGroup.Item className="priceD">
                  {t("Token ID")} <a href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${token_id}`} target="_blank"><span style={{ color: '#BAFF85 !important' }}>{token_id}</span></a>
                </ListGroup.Item>
                <ListGroup.Item className="priceD">
                  {t("Owner")} <a href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${item.owner}`} target="_blank" className="itemS"><span style={{ color: '#BAFF85 !important' }}>{item?.owner == null ? "--" : item?.owner}</span></a>
                </ListGroup.Item>



                <ListGroup.Item className="priceD">
                  {t("Class")} <span>{item?.category}</span>{' '}
                </ListGroup.Item>


              </ListGroup>
            </div>


          </Col>

        </Row>
        <Row>
          <Col>
            <div>
              <h4>{t("More")}</h4>
              <ListGroup variant='flush' className='card cardab'>
                <ListGroup.Item className="priceD">
                  {t("Token URI")} <a href={item?.tokenURI.replace("ipfs://", AppParams.IPFS_GATWAY)} target="_blank" className="itemS"><span style={{ color: '#BAFF85 !important' }}>{item?.tokenURI}</span></a>
                </ListGroup.Item>

                {configuration?.projectPdfDescriptionUriFr.length > 3 &&
                  <ListGroup.Item className="priceD">
                    {t("Project info")} <a href={i18n.language == 'fr' ? configuration?.projectPdfDescriptionUriFr: configuration?.projectPdfDescriptionUriEn} className="itemS" target="_blank"><span style={{ color: '#BAFF85 !important' }}>{i18n.language == 'fr' ? configuration?.projectPdfDescriptionUriFr: configuration?.projectPdfDescriptionUriEn}</span></a>

                  </ListGroup.Item>
                }

                {/*<div style={{ textAlign: 'center' }}>
                <Button className='download-pdf m-3' variant='primary' onClick={(e) => { downloadURI(item?.pdfFile.replace("ipfs://", AppParams.IPFS_GATWAY), item?.category) }}>
                  Download PDF
  </Button>
              </div>*/}
                <ListGroup.Item>
                  <div style={{ textAlign: 'center' }}>{t("Description")}</div> <br /> <div className="spandesc"><span>{
                    i18n.language == 'fr' ? item?.descriptionFr : item?.descriptionEn
                  }</span></div>{' '}
                </ListGroup.Item>
              </ListGroup>

            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default NFTDetails
