import { AppParams } from "../config";
import httpService from "./httpService";




export const handleRegister = async (collectionName, account, signature) => {
    return new Promise((resolve, reject) => {

        const username =collectionName+"&-&"+account;

        httpService
            .post(
                `${AppParams.BACKEND_URL}/users/subscribe`,

                {
                    username: account,
                    password: signature,
                    confirmPassword: signature,
                    publicAddress: account,
                    firstname: null,
                    lastname: null,
                    email: null,
                    referralId: null,
                    collectionName:collectionName
                }
            )
            .then((response) => {
                handleLogin(username, signature)
                    .then((response) => {
                        resolve();
                    })
                    .catch((error) => {
                        console.log(error);
                        reject();
                    });
            })
            .catch((error) => {
                // if (error.response.data.message == "Duplicate username") {
                handleLogin(username, signature)
                    .then((response) => {
                        resolve();
                    })
                    .catch((error) => {
                        console.log(error);
                        reject();
                    });

                // }else{
                //   reject();
                // }
            });
    });
};

export const handleLogin = (username, password) => {
    return new Promise((resolve, reject) => {
        httpService
            .post(
                `${AppParams.BACKEND_URL}/auth/login`,

                {
                    username: username,
                    password: password,
                }
            )
            .then((response) => {
                httpService.setJwt(response.data.token);
                resolve();
            })
            .catch((error) => {
                reject();
            });
    });
};