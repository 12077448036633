import { useEffect, useState } from 'react'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import {
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Spinner,
  Container
} from 'react-bootstrap'

import { Pagination } from 'antd';
import { useRecoilState } from 'recoil';

import {
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { AppParams, MarketPlaceClient, MinterMarketPlaceClient, TokensClient } from '../config'

import NFTCard from './NFTCard'
import Collapsible from 'react-collapsible'
import React from 'react'
import { collectionDetails, itemsForSale, itemsForSaleByCategory, listedCollectionsMinterMarketPlace } from '../services/graphql.js'
import { accountState, collectionNameState, referralAddressState, referralReductionState, refreshIndex, whitelistActivatedState } from '../services/atoms'
import TokenClassFilter from '../components/TokenClassFilter';
import CollectionInfos from '../components/CollectionInfos';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CollectionCard from '../components/CollectionCard';
import SwitchDesign from '../components/SwitchDesign';
import { ethers } from 'ethers';
import { MinterMarketplaceAbi } from '../artifacts/contracts-abis/MinterMarketplace-abi';
import { async } from 'q';

const Nfts = () => {


  const navigate = useNavigate();
  const { collectionParam, categoryParam } = useParams()
  let [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();

  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')
  const [minValue, setMinValue] = useState('0')
  const [maxValue, setMaxValue] = useState(Number.MAX_VALUE.toString())
  const [page, setPage] = useState(0)

  const [nftsList, setNftsList] = useState({ data: [] })

  // Search Filter
  const [filterInput, setFilterInput] = useState('')
  const [selectedOrder, setSelectedOrder] = useState('tokenId-asc')
  const [category, setCategory] = useState('')
  const [visible, setVisible] = React.useState(false);

  const [refresh, setRefresh] = useRecoilState(refreshIndex);
  const [tokenClasses, setTokenClasses] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [dataLoading, setDataLoading] = useState(false);

  const [referralReduction, setReferralReduction] = useRecoilState(referralReductionState)

  const [collectionInfos, setCollectionInfos] = useState({
    isMinterMarketplace: null
  })
  const [whitelistActivated, setWhitelistActivated] = useRecoilState(whitelistActivatedState)

  const [collectionName, setCollectionName] = useRecoilState(collectionNameState)

  const [referralAddress, setReferralAddress] =useRecoilState(referralAddressState);

  const [account, setAccount] = useRecoilState(accountState)



  useEffect(async() => {
    if (collectionInfos.isMinterMarketplace == null || account == '') {
      setReferralReduction(0)
      return;
    } else {

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const markAddress = collectionInfos.isMinterMarketplace ? AppParams.MINTER_MARKET_ADDRESS : AppParams.MARKET_ADDRESS

      const contractMarketplace = new ethers.Contract(
        markAddress,
        MinterMarketplaceAbi,
        signer
      );

      let referrals = await contractMarketplace.getReferralsList(collectionInfos.collectionAddress, account);
      if (referrals[0].account != "0x0000000000000000000000000000000000000000") {
        let reduction = parseInt(await contractMarketplace.referralLevelReward(collectionInfos.collectionAddress, 0));
        setReferralReduction(reduction / 10 ** 6)

      } else {
        setReferralReduction(0)
      }
    }

  }, [collectionInfos.isMinterMarketplace, account])



const fetchPagesInfos = (res) => {
  let totalElements = 0;
  let categorysInfos = {};
  for (let i = 0; i < res.data.tokenClasses.length; i++) {
    totalElements += parseInt(res.data.tokenClasses[i].numberTokensListed)
    categorysInfos[res.data.tokenClasses[i].id] = res.data.tokenClasses[i].numberTokensListed
  }
  setTokenClasses(categorysInfos);
  setTotalElements(totalElements);
}


const remoteData = (query) => {
  setNftsList({ data: [] });
  setDataLoading(true)
  if (collectionInfos.isMinterMarketplace) {
    return MinterMarketPlaceClient.query({
      query: listedCollectionsMinterMarketPlace,
      fetchPolicy: "network-only",
      variables: {
        collection: collectionInfos.collectionAddress
      }
    }).then((res) => {
      setNftsList({ data: res.data.tokenClasses })

      if (res.data.tokenClasses[0].tokenContract.whitelist != "0x0000000000000000000000000000000000000000") {
        console.log("whitelist",res.data.tokenClasses[0].tokenContract.whitelist)
        setWhitelistActivated(true)
      }else{
        setWhitelistActivated(false)
      }
      //setDataLoading(false)
    })
  } else {
    const queryCall = query.category == '' ? itemsForSale : itemsForSaleByCategory;
    return MarketPlaceClient.query({
      query: queryCall,
      fetchPolicy: "network-only",
      variables: {
        collection: collectionInfos.collectionAddress,
        category: query.category,
        minPrice: query.minPrice,
        maxPrice: query.maxPrice,
        filter: query.filter,
        offset: page * query.pageSize,
        limit: query.pageSize,
        orderBy: query.orderBy === undefined ? "price" : query.orderBy,
        orderDirection: query.orderDirection === undefined || query.orderDirection === "" ? "asc" : query.orderDirection
      }
    }).then((res) => {
      //console.log("aaaaaaaa",res,queryCall)
      setNftsList({ data: res.data.nfts })
      if (res.data.nfts[0]?.tokenContract.whitelist != "0x0000000000000000000000000000000000000000") {
        console.log("whitelist",res.data.nfts[0]?.tokenContract.whitelist)
        setWhitelistActivated(true)
      }else{
        setWhitelistActivated(false)
      }
      setDataLoading(false)
    })
  }
}

useEffect(() => {
  if (collectionParam == null) {
    if (localStorage.getItem("collection") == null) {
      navigate("/nfts/" + AppParams.DefaultCollection);
      return;
    } else {
      navigate("/nfts/" + localStorage.getItem("collection"));
      return;
    }
  }
  if(searchParams.get("Ref")!=null){
    let ref =  searchParams.get("Ref");
    localStorage.setItem("referralAddress",ref)
    setReferralAddress(ref);
  }
}, [])

useEffect(() => {
  if (collectionParam == null || collectionInfos.isMinterMarketplace == null || collectionInfos.collectionAddress == null) return;
  localStorage.setItem("collection", collectionParam)
  setCollectionName(collectionParam)
  remoteData(
    {
      category: category,
      minPrice: minValue,
      maxPrice: maxValue,
      filter: filterInput,
      page: 0,
      pageSize: 12,
      orderBy: selectedOrder.split("-")[0],
      orderDirection: selectedOrder.split("-")[1]
    }
  );
}, [filterInput, selectedOrder, refresh, page, category, collectionParam, collectionInfos.collectionAddress,collectionInfos.isMinterMarketplace])


useEffect(() => {
  if (collectionParam == null) return;
  TokensClient.query({
    query: collectionDetails,
    fetchPolicy: 'network-only',
    variables: {
      collection: collectionParam,
    },
  }).then((res) => {
    let metas = {
      isMinterMarketplace: res.data.tokenContracts[0].isMinterMarketplace,
      collectionAddress: res.data.tokenContracts[0].id,
    }
    setCollectionInfos(metas)
  })

}, [collectionParam])

useEffect(() => {
  setPage(0)
},
  [category])

const paginationChanged = (pageNumber, pageSize) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
  setPage(pageNumber - 1);
}


const sortChanged = (event) => {
  setSelectedOrder(event.target.value)
}

if (loadingState === 'loaded' && !nfts.length)
  return <h1 className='px-20 py-10 text-3xl'>{t("No items in marketplace")}</h1>
return (
  <div>

    <SwitchDesign collectionName={collectionParam} ></SwitchDesign>
    {


      <Row>

        {
          !collectionInfos.isMinterMarketplace ?
            <Col sm={12} md={3} lg={3} xl={2}>
              <h5
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
                className="element-hidden"
              >
                {t("Filtre")}
              </h5>

              <div className="element-hidden">
                <FilterC
                  trigger=''
                  contenu={
                    <div className='AlignB'>
                      <TokenClassFilter collection={collectionInfos.collectionAddress} defaultValue={searchParams.get("category")} onClassesFound={(values) => { fetchPagesInfos(values) }} onSelect={(value) => setCategory(value)}></TokenClassFilter>
                    </div>
                  }
                />
              </div>
            </Col>
            :
            null
        }


        <Col sm={12} md={collectionInfos.isMinterMarketplace ? 12 : 9} lg={collectionInfos.isMinterMarketplace ? 12 : 9} xl={collectionInfos.isMinterMarketplace ? 12 : 10}>
          <CollectionInfos collection={collectionParam}></CollectionInfos>
          <br />
          <div className="AlignTri mt-4">
            <select onChange={sortChanged} value={selectedOrder} >
              <option value='updatedAt-desc'>{t("Latest")}</option>
              <option value='tokenId-asc'>{t("Lowest ID")}</option>
              <option value='tokenId-desc'>{t("Highest ID")}</option>


            </select>
            {!collectionInfos.isMinterMarketplace && <div className="filterMobile"><button className="element-hide-l codeD" onClick={() => setVisible(!visible)}>{t("More option")}</button></div>}
          </div>
          {/* Mobile*/}
          {!collectionInfos.isMinterMarketplace ?
            <div className="element-hide-l">
              <div className={visible ? 'element-visible' : 'element-hidden'}>
                <FilterC
                  trigger='Class'
                  contenu={
                    <div className='AlignB'>
                      <TokenClassFilter collection={collectionInfos.collectionAddress} defaultValue={searchParams.get("category")} onClassesFound={(values) => { fetchPagesInfos(values) }} onSelect={(value) => setCategory(value)}></TokenClassFilter>
                    </div>
                  }
                />
              </div>
            </div>
            :
            null
          }
          {/*fin tri mobile */}

          <Row>
            <Col
              sm={12}
              md={12}
              lg={{ span: 4, offset: 8 }}
              xl={{ span: 4, offset: 8 }}
            >
              <div className='filterItem d-none'>
                <DropdownButton id='dropdown-item-button' title=' Lowest ID '>
                  <Dropdown.Item
                    as='button'
                    id='priceAsc'
                  /*onClick={() => sortPriceAsc()}*/
                  >
                    Price
                  </Dropdown.Item>
                  <Dropdown.Item as='button'>Class</Dropdown.Item>
                </DropdownButton>
                <i className='fa-brands fa-microsoft'></i>
                <i className='fa-solid fa-list'></i>
              </div>
            </Col>
          </Row>
          {
            !collectionInfos.isMinterMarketplace ?
              <div className="test2">
                <Row>
                  {
                    nftsList.data.length > 0 ?
                      nftsList.data.map((nft, index) => (
                        <Col key={index} sm={12} md={6} lg={4} xl={3}>
                          <NFTCard nft={nft} collectionName={collectionParam} />
                        </Col>
                      ))
                      :
                      dataLoading ?
                        <div style={{ height: '71vh' }} className="centerV">
                          <Spinner style={{ height: "2rem", width: "2rem" }} animation="border" />
                        </div>
                        :
                        <div style={{ height: '70vh' }} className="centerV">
                          <span style={{ verticalAlign: 'middle', fontSize: 18 }}>{t("No assets")}</span>
                        </div>
                  }
                </Row>
                <div>
                  <Pagination onChange={paginationChanged} current={(page + 1)} defaultCurrent={(page + 1)} total={category == '' ? totalElements : tokenClasses[category]} />
                </div>
              </div>
              : null
          }
          {
            collectionInfos.isMinterMarketplace ?
            <Col sm={12} md={6}   lg={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} >
              <Container>

                <Row>
                  {
                    nftsList.data.length > 0 ?
                      nftsList.data.map((nft, index) => (
                        <Col key={index} sm={12} md={6} lg={4} xl={4}>
                          <CollectionCard collection={nft} collectionName={collectionParam}/>
                        </Col>
                      ))
                      :
                      dataLoading ?
                        <div style={{ height: '71vh' }} className="centerV">
                          <Spinner style={{ height: "2rem", width: "2rem" }} animation="border" />
                        </div>
                        :
                        <div style={{ height: '70vh' }} className="centerV">
                          <span style={{ verticalAlign: 'middle', fontSize: 18 }}>{t("No assets")}</span>
                        </div>
                  }
                </Row>
              </Container>
              </Col>
              : null
          }

        </Col>
      </Row>
    }
  </div >
)
}

function FilterC({ trigger, contenu }) {
  return (
    <Collapsible open={true} trigger={trigger}>
      <div>{contenu}</div>
    </Collapsible>
  )
}

export default Nfts
