import React from "react";
//import wl from './Switch_design.json';

export default function SwitchDesign(props) {
  const [themeNumber, setThemeNumber] = React.useState(0);

  const handleChange = (NumberDesign) => {
    setThemeNumber(NumberDesign);
  };

  React.useEffect(() => {
    if (props.collectionName == null) {
      return;
    }

    fetch("/styles/Switch_design.json")
      .then((response) => response.json())
      .then((wl) => {
        let { collectionName } = props;
        // console.log(
        //   "props.collectionName",
        //   props.collectionName,
        //   wl[props.collectionName]
        // );
        for (var element in wl[collectionName]) {
          document.documentElement.style.setProperty(
            element,
            wl[collectionName][element]
          );
        }
      });
  }, [props.collectionName]);

  return <div></div>;
}
