import { atom } from "recoil";

export const accountState = atom({
  key: "accountState",
  default: "",
});

export const pendingTransactions = atom({
  key: "pendingTransactions",
  default: [],
});

export const refreshIndex = atom({
  key: "refreshIndex",
  default: 0,
});
export const purchaseEnd = atom({
  key: "purchaseEnd",
  default: false,
});

export const mainTokenTotalForSale = atom({
  key: "mainTokenTotalForSale",
  default: 0,
});
export const assetTokenTotalForSale = atom({
  key: "assetTokenTotalForSale",
  default: 0,
});

export const convertPriceUsdAvax = atom({
  key: "convertPriceUsdAvax",
  default: 0,
});

export const avaxValueUsd = atom({
  key: "avaxValueUsd",
  default: 0,
});

export const feesReducerActivatedToken = atom({
  key: "feesReducerActivatedToken",
  default: 0,
});
export const feesReducerActivatedTokenRate = atom({
  key: "feesReducerActivatedTokenRate",
  default: 0,
});
export const referralAddressState = atom({
  key: "referralAddressState",
  default: null,
});
export const kycState = atom({
  key: "kycState",
  default: false,
});
export const showSpinKycState = atom({
  key: "showSpinKycState",
  default: false,
});

export const loggedInUserState = atom({
  key: "loggedInUserState",
  default: null,
});
export const networkIdState = atom({
  key: "networkIdState",
  default: null,
});
export const referralReductionState = atom({
  key: "referralReductionState",
  default: 0,
});
export const isFactoryOwnerState = atom({
  key: "isFactoryOwnerState",
  default: false,
});
export const configurationState = atom({
  key: "configurationState",
  default: {
    name: "--",
    logo: "",
    title: "--",
    banner: "",
    projectDescription: "--",
    projectPdfDescriptionUriEn: "--",
    projectPdfDescriptionUriFr: "--",
    conditionsGeneralesUriFr: "--",
    conditionsGeneralesUriEn: "--",
    disclaimerTextFr: "",
    disclaimerTextEn: "",
  },
});

export const web3ApiState = atom({
  key: "web3Api",
  dangerouslyAllowMutability: true,
  default: { provider: null, isLoading: false, success: false },
});

export const whitelistActivatedState = atom({
  key: "whitelistActivatedState",
  default: null,
}); 
export const isConnectingState = atom({ key: "isConnecting", default: false });


export const collectionNameState = atom({
  key: "collectionNameState",
  default: null,
}); 
