import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil';
import { accountState } from '../../services/atoms';
import { useTranslation } from 'react-i18next';

export default function ReferralLink({ collectionName }) {
    const { t, i18n } = useTranslation();
    const [account, setAccount] = useRecoilState(accountState)

    const [link, setLink] = useState('');
    const [copied, setCopied] = useState(false);

    const copyLink = (link) => {
        navigator.clipboard.writeText(link)
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    useEffect(() => {
        setLink(`${window.location.origin}/nfts/${collectionName}?Ref=${account}`)
    }, [collectionName,account])
    

    return (
        <div>
            <div className="my-3 bgBlocAff">
                <div>
                    <h2>{t("Referral Link")}</h2>
                    <div>
                        {link}
                    </div>
                    <button className="btnTop" onClick={() => copyLink(link)}>
                        {copied ? <span className="copied">{t("Link copied")}</span> : <span>{t("Copy to clipboard")}</span>}
                    </button>
                </div>
            </div>
        </div>
    )
}
