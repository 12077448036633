import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Row, Col, Image, ListGroup, Card, Form, Button } from 'react-bootstrap'
// import Rating from '../components/Rating'
//import products from '../products'
import { ethers } from 'ethers'
import axios from 'axios'

import { AppParams, TokensClient } from '../config'

import { myTokenDetails } from '../services/graphql'
import { useTranslation } from 'react-i18next'
import SwitchDesign from '../components/SwitchDesign'
import { collectionNameState } from '../services/atoms'
import { useRecoilState } from 'recoil'
import httpService from '../services/httpService'


const MyAssetDetails = () => {
  const { token_id, contractAddress } = useParams()
  const navigate = useNavigate()
  const [nft, setNft] = useState({ category: '--' })
  const { t, i18n } = useTranslation();

  const [collectionName, setCollectionName] = useRecoilState(collectionNameState)
  const [configuration, setConfiguration] = useState(null)

  const [item, setItem] = useState({
    image: ("/images/Spin-det.svg"),
    name: "--",
    description: "--",
    tokenURI: "--",
    pdfFile: '--',
  })

  useEffect(() => {
    // Load NFTS
    loadNFTs()
  }, [])

  async function loadNFTs() {

    TokensClient.query({
      query: myTokenDetails,
      fetchPolicy: 'network-only',
      variables: {
        contractAddress: contractAddress,
        tokenId: parseInt(token_id),
      }
    }).then((res) => {
      console.log("res.data.nfts[0].tokenURI", res)
      let item = {
        owner: res.data.nfts[0].currentOwner.id,
        category: res.data.nfts[0].tokenClass.name,
        contractName: res.data.nfts[0].tokenContract.name,
      }
      setNft(item)

      setCollectionName(res.data.nfts[0].tokenContract.name);
      localStorage.setItem("collection", res.data.nfts[0].tokenContract.name)
      axios.get(res.data.nfts[0].tokenURI.replace("ipfs://", AppParams.IPFS_GATWAY)).then(meta => {
        let tokensMeta = {
          image: meta.data.image.replace("ipfs://", AppParams.IPFS_GATWAY),
          name: meta.data.name,
          descriptionFr: meta.data.descriptionFr,
          descriptionEn: meta.data.descriptionEn,
          tokenURI: res.data.nfts[0].tokenURI,
          pdfFile: meta.data.pdfFile != null ? meta.data.pdfFile : "--",
        }
        setItem(tokensMeta);
      })

    })
  }

  useEffect(() => {
    if (collectionName == null) return;
    httpService.get(
      `${AppParams.BACKEND_URL}/config/projects?projectID=${collectionName}`
      ,
    )
      .then(response => {
        setConfiguration(response.data)
      })
      .catch(error => {
        console.log(error)
      });
  }, [collectionName])

  function showOnExplorer(event) {
    event.stopPropagation();
    window.open(`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${token_id}`, "_blanc")
  }

  /*function downloadURI(uri, tokenClass) {

    fetch(uri)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = tokenClass + '.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })

  }*/

  return (
    <>
      <SwitchDesign collectionName={nft.contractName} ></SwitchDesign>

      <div className='screenD container'>
        <Link className='btn btn-outline-primary my-2 pl-0' to='/my-assets'>
          <i className='fa-solid fa-angle-left'></i> {t("Back")}
        </Link>
        <Row>
          <Col>
            <span className='codeD' style={{ cursor: 'pointer' }} onClick={showOnExplorer}>#{token_id}</span>
          </Col>
        </Row>
        <Card.Text as='h3'>{nft?.name}</Card.Text>
        <Row className="rowD">
          <Col md={6}>
            <div className="p25">

              <Image src={item?.image} alt={item?.name} style={{ borderRadius: 20 }} fluid />


            </div>


            {/*<div style={{ textAlign: 'center' }}>
                <Button className='download-pdf m-3' variant='primary' onClick={(e) => { downloadURI(item?.pdfFile.replace("ipfs://", AppParams.IPFS_GATWAY), nft?.category) }}>
                  Download PDF
                </Button>
              </div>*/}


          </Col>
          <Col md={6} className="colS">

            <div>
              <h4>{t("About")}</h4>
              <ListGroup variant='flush' className='card cardab'>
                <ListGroup.Item className="priceD">
                  {t("")}Contract <a href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}`} target="_blank" className="itemS"><span style={{ color: '#BAFF85 !important' }}>{contractAddress}</span></a>
                </ListGroup.Item>
                <ListGroup.Item className="priceD">
                  {t("Token ID")} <a href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${token_id}`} target="_blank"><span style={{ color: '#BAFF85 !important' }}>{token_id}</span></a>
                </ListGroup.Item>
                <ListGroup.Item className="priceD">
                  {t("Owner")} <a className="itemS" href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${nft.owner}`} target="_blank"><span style={{ color: '#BAFF85 !important' }}>{nft?.owner == null ? "--" : nft?.owner}</span></a>
                </ListGroup.Item>
                <ListGroup.Item className="priceD">
                  {t("Class")} <span>{nft?.category}</span>{' '}
                </ListGroup.Item>
                <ListGroup.Item className="priceD">
                  {t("Token URI")} <a className="itemS" href={item?.tokenURI.replace("ipfs://", AppParams.IPFS_GATWAY)} target="_blank"><span style={{ color: '#BAFF85 !important' }}>{item?.tokenURI}</span></a>
                </ListGroup.Item>

                {item?.pdfFile.length > 3 &&
                  <ListGroup.Item className="priceD" data-truncate="on ad">
                    {t("Project info")} <a className="itemS" href={item?.pdfFile.replace("ipfs://", AppParams.IPFS_GATWAY)} target="_blank"><span style={{ color: '#BAFF85 !important' }}>{item?.pdfFile}</span></a>


                  </ListGroup.Item>
                }

              </ListGroup>
            </div>


          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <ListGroup variant='flush' className='card cardab'>
                <ListGroup.Item>
                  <div style={{ textAlign: 'center' }}> {t("Description")}</div><br />  <div className="spandesc"><span>
                  {i18n.language == 'fr' ? item?.descriptionFr : item?.descriptionEn}
                    </span></div>{' '}
                </ListGroup.Item>
              </ListGroup>

            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default MyAssetDetails
