import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { MarketPlaceClient } from '../config';
import { tokenCategoriesList } from '../services/graphql';
import { useTranslation } from 'react-i18next';

export default function TokenClassFilter(props) {

    const [classesFilter, setClassesFilter] = useState([]);
    const [selected, setSelected] = useState('')
    let defaultValue = props.defaultValue
    
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (props.collection == null) return;
        fetchClasses()
    }, [props.collection])

    const fetchClasses = () => {
        MarketPlaceClient.query({
            query: tokenCategoriesList,
            fetchPolicy: "network-only",
            variables: {
                collection: props.collection
            }
        }).then((res) => {
            setClassesFilter(res.data.tokenClasses);
            if(props.onClassesFound != undefined){
                props.onClassesFound(res)
            }
            if(defaultValue != null){
                handleOnSelect(defaultValue);
                defaultValue = null
            }
        })
    }

    const handleOnSelect = (value) => {
        props.onSelect(value);
        setSelected(value)
    }

    return (
        <>
            <Button key={''} className={selected == '' ? 'btn btn-primary-active' : 'btn btn-primary'} onClick={() => handleOnSelect('')}>{t('All')}</Button>
            {
                classesFilter.map(element => {
                    return <Button key={element.id} className={selected == element.id ? 'btn btn-primary-active' : 'btn btn-primary'} onClick={() => handleOnSelect(element.id)}>{element.name}</Button>
                })
            }
        </>
    )
}
