import React, { useState, useEffect } from 'react'

import { Row, Col, Container } from 'react-bootstrap'

import AssetCard from './AssetCard'

import { TokensClient } from '../config'
import { myTokens, myTokensByCollection } from '../services/graphql'

import { useRecoilState } from 'recoil';
import { accountState, collectionNameState, refreshIndex } from '../services/atoms'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MyAsstesCollectionFilter from '../components/MyAsstesCollectionFilter'
import SwitchDesign from '../components/SwitchDesign'
import CollectionInfos from '../components/CollectionInfos'

const MyAssets = () => {

  const { collectionName } = useParams()

  const [collectionNameGlob, setCollectionNameGlob] = useRecoilState(collectionNameState)

  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')
  const [category, setCategory] = useState('')
  const [nftsList, setNftsList] = useState({ data: [] })

  // Search Filter
  const [filterInput, setFilterInput] = useState('')
  const [selectedOrder, setSelectedOrder] = useState('id-desc')

  const [account] = useRecoilState(accountState);
  const [refresh, setRefresh] = useRecoilState(refreshIndex);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (collectionName == null) return;
    localStorage.setItem("collection", collectionName)
    setCollectionNameGlob(collectionName)
  }, [collectionName])


  const remoteData = (query) => {
    console.log("query.account", query)
    const queryCall = query.category == '' ? myTokens : myTokensByCollection;
    return TokensClient.query({
      query: queryCall,
      fetchPolicy: 'network-only',
      variables: {
        account: query.account.toLowerCase(),
        category: "",
        filter: query.category,
        offset: query.page * query.pageSize,
        limit: query.pageSize,
        orderBy: query.orderBy === undefined ? "id" : query.orderBy,
        orderDirection: query.orderDirection === undefined || query.orderDirection === "" ? "desc" : query.orderDirection
      }
    }).then((res) => {
      setNftsList({ data: res.data.nfts })
    })
  }



  useEffect(async () => {
    remoteData(
      {
        account: account,
        category: collectionName,
        filter: filterInput,
        page: 0,
        pageSize: 999,
        orderBy: selectedOrder.split("-")[0],
        orderDirection: selectedOrder.split("-")[1]
      }
    );
  }, [filterInput, selectedOrder, category, account, refresh])

  if (loadingState === 'loaded' && !nfts.length)
    return <h1 className='px-20 py-10 text-3xl'>{t("No Assets Owned")}</h1>
  return (
    <div>
      <SwitchDesign collectionName={collectionName} ></SwitchDesign>
      {/*<div className="AliCat">
        <MyAsstesCollectionFilter account={account} onSelect={(value) => setCategory(value)}></MyAsstesCollectionFilter>
  </div>*/}
      <CollectionInfos collection={collectionName}/>
      <Row className="mt-5">
      <Col sm={12} md={6}   lg={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} >
      <Container>
      <Row>

        {
          nftsList.data.length > 0 ?
            nftsList.data.map((nft, index) => (
              <Col key={index} sm={12} md={6} lg={4} xl={4}>
                <AssetCard nft={nft} />
              </Col>
            ))
            :
            <div style={{ height: '150px', lineHeight: '150px', textAlign: 'center' }}>
              <span style={{ verticalAlign: 'middle', fontSize: 18 }}>{t("No assets yet. Get your first NFT from")} <Link to={category != '' ? "/nfts" + localStorage.getItem("collection") + "?category=" + category : "/nfts/" + localStorage.getItem("collection")}>{t("here")}</Link></span>
            </div>

        }
        </Row>
        </Container>
        </Col>
      </Row>
    </div>
  )
}

export default MyAssets
