import React, { useEffect, useState } from 'react';
import { Client } from '@stomp/stompjs';


export default function WebsocketClientComponent(props) {



    useEffect(() => {

        return () => {
            if (client != null) {
                client.deactivate();
            }
        }
    }, [])
    let client
    useEffect(() => {

        console.log("WS Connected",props.webSocketToken)

        if (client != null) {
            client.deactivate();
        }
        if (props.webSocketToken == null) {
            return;
        }
        client = new Client({
            brokerURL: props.websocketURL + props.webSocketToken,
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: (log) => {
                console.log("WS Connected")
                /*client.subscribe('/events/global-notify', function (msg) {
                    console.log("aaaaa0", msg)
                    if (msg.body) {
                        var jsonBody = JSON.parse(msg.body);
                        if (jsonBody.message) {
                            currentComponent.setState({ messages: jsonBody.message })
                        }
                    }
                });*/
                client.subscribe(props.privateNotificationsEndpoint, function (msg) {
                    if (msg.body) {
                        var jsonBody = JSON.parse(msg.body);
                        if (jsonBody.content) {
                            props.onPrivateNotificationReceived(jsonBody.content)
                        }
                    }
                })
            },
            onDisconnect: () => {
                console.log("WS Disconnected")
            }
        });

        client.activate();
    }, [props.webSocketToken])


    return (
        <div></div>
    )
}
