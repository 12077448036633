import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { configurationState } from '../services/atoms';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [configuration, setConfiguration] = useRecoilState(configurationState);
  return (
    <footer>
      
       
          <div>
            {t("Copyright")} &copy; {t("O2S Martketplace")}
          </div>
          <div className='text-center py-3'>
            <a href={i18n.language == 'fr' ? configuration?.conditionsGeneralesUriFr: configuration?.conditionsGeneralesUriEn} className="lienCGS" target='_blank'>{t("General conditions of use and sale")}.</a>
          </div>
        </footer>
  
  )
}

export default Footer
