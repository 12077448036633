import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react'
import { useState } from 'react';
import axios from 'axios';
import { collectionDetails } from '../services/graphql';
import { useEffect } from 'react';
import { AppParams, TokensClient } from '../config';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next'
import httpService from '../services/httpService';
import { configurationState } from '../services/atoms';
import { useRecoilState } from 'recoil';

export default function PurchaseDisclaimer(args) {


    const { t, i18n } = useTranslation();

    const [configuration, setConfiguration] = useRecoilState(configurationState);


    const [conditionsAcceptes, setConditionsAcceptes] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const handleClose = () => {
        args.onCancel();
    }
    const handleOk = () => {
        args.onOK();
    }



    useEffect(() => {
        setConditionsAcceptes(false);
        setIsLoading(true)
        //console.log("cgs",args)
        if (args.isModalOpen == false) return;
        if (args.collectionName == null) return;


        httpService.get(
            `${AppParams.BACKEND_URL}/config/projects?projectID=${args.collectionName}`
            ,
        )
            .then(response => {
                setConfiguration(response.data)
                setIsLoading(false)
            })
            .catch(error => {
                console.log(error)
                setIsLoading(false)
            });



    }, [args.isModalOpen])

    const handleOnAcceptChanged = (e) => {
        setConditionsAcceptes(e.target.checked);
        console.log(e.target.checked)
    }

    return (
        <>


            <Modal show={args.isModalOpen && isLoading == false} onHide={() => { handleClose() }}>
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    <h3 style={{ textAlign: 'center' }}>{t('Disclaimer')}</h3>


                    <span>
                        <div dangerouslySetInnerHTML={{
                            __html: i18n.language == 'fr' ? configuration.disclaimerTextFr : configuration.disclaimerTextEn }} />
                    </span>

                    <Checkbox onChange={handleOnAcceptChanged} className="checkM">
                        <span>{t("I accept")} </span>
                        {/*<span> {t("of")} </span>
                      <span>{infos.projectName}.</span> */}
                    </Checkbox>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        {t("Cancel")}
                    </Button>
                    <Button disabled={!conditionsAcceptes} variant="primary" onClick={handleOk}>
                        {t("Process purchase")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
