import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';

export default function ReferralRewards({ totalCollectedReferralreward,paymentTokenSymbol }) {

    const { t, i18n } = useTranslation();

    return (
        <div><div>
            <div className="my-3 bgBlocAff">
                <div>
                    <h2>{t("Total rewards")}</h2>
                    <span>{totalCollectedReferralreward} {paymentTokenSymbol}</span>
                </div>
            </div>
        </div></div>
    )
}
