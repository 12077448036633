import React, { useState } from 'react';
import { ethers } from 'ethers';

function ContractFunctionList({ abi,ADDRESS }) {
    const [functions, setFunctions] = useState([]);
    const [selectedFunction, setSelectedFunction] = useState(null);
    const [inputs, setInputs] = useState([]);
  
   
  
    const getFunctions = async () => {
      const newFunctions = await Promise.all(
        abi.map(async (item) => {
          if (item.type === 'function') {
            const name = item.name;
            const stateMutability = item.stateMutability;
            const inputs = item.inputs.map((input) => ({ name: input.name, type: input.type }));
            const outputs = item.outputs.map((output) => ({ name: output.name, type: output.type }));
  
            return { name, inputs, outputs,stateMutability };
          }
        })
      );
  
      setFunctions(newFunctions.filter((f) => f));
    };
  
    const handleSelect = (event) => {
      const selectedFunctionName = event.target.value;
      setSelectedFunction(selectedFunctionName);
      const selectedFunction = functions.find((f) => f.name === selectedFunctionName);
      setInputs(selectedFunction.inputs.map((input) => ({ name: input.name, type: input.type, value: '' })));
    };
  
    const handleInputChange = (event, index) => {
      const newInputs = [...inputs];
      newInputs[index].value = event.target.value;
      setInputs(newInputs);
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();

      const provider = new ethers.providers.Web3Provider(window.onBoardProvider, 'any')
      const signer = await provider.getSigner()

      const contract = new ethers.Contract(ADDRESS, abi, provider).connect(signer);


      const selectedFunctionAbi = functions.find((f) => f.name === selectedFunction);
      const inputValues = inputs.map((input) => input.value);
  
      console.log("selectedFunctionAbi.stateMutability",selectedFunctionAbi)
      if (selectedFunctionAbi.stateMutability === 'view') {
        const result = await contract[selectedFunction](...inputValues);
        console.log(result);
      } else {
        const overrides = {
          gasLimit: 2000000,
          //value: ethers.utils.parseEther('1.0')
        };
  
        const tx = await contract[selectedFunction](...inputValues, overrides);
        await tx.wait();
        console.log('Transaction mined!');
      }
    };
  
    return (
      <div>
        <button onClick={getFunctions}>Get Functions</button>
        {functions.length > 0 && (
          <form onSubmit={handleSubmit}>
            <select onChange={handleSelect}>
              <option value="">Select a function</option>
              {functions.map((func) => (
                <option key={func.name} value={func.name}>
                  {func.name}
                </option>
              ))}
            </select>
            {inputs.map((input, index) => (
              <div key={index}>
                <label>{input.name}: </label>
                {input.type === 'address' ? (
                  <input type="text" value={input.value} onChange={(event) => handleInputChange(event, index)} />
                ) : input.type.startsWith('uint') ? (
                  <input type="number" value={input.value} onChange={(event) => handleInputChange(event, index)} />
                ) : (
                  <input type="text" value={input.value} onChange={(event) => handleInputChange(event, index)} />
                )}
              </div>
            ))}
            <button type="submit">Execute</button>
          </form>
        )}
      </div>
    );
  }

export default ContractFunctionList;