import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import NFTDetails from "./screens/NFTDetails";
import Nfts from "./screens/Nfts";
//import CreateItem from './screens/CreateItem'
import MyAssets from "./screens/MyAssets";
//import CreatorDashboard from './screens/CreatorDashboard'
//import CreateAsset from './screens/CreateAsset'
import MyAssetDetails from "./screens/MyAssetDetails";
//import BatchCreateItems from './screens/BatchCreateItems'
//import BatchCreateEquipements from './screens/BatchCreateEquipements'
//import BatchCreateReducer from './screens/BatchCreateReducer'
import DeployNFTContract from "./screens/DeployNFTContract";
import ManageApp from "./screens/ManageApp";
import MintTokens from "./screens/MintTokens";
import CollectionDetails from "./screens/CollectionDetails";
import ManageCollection from "./screens/ManageCollection";
import Torus from "@toruslabs/torus-embed";
import HowItWork from "./screens/HowItWork";
import UserProfile from "./screens/UserProfile";

/****web3modal start*/

/****web3modal end */
const App = () => {
  return (
    <Router>
      <>
        <Header />
        <main style={{ marginTop: 135 }}>
          <Container fluid>
            <Routes>
              <Route path="/" element={<Nfts />} exact />
              <Route path="/product/:id" element={<ProductScreen />} />
              <Route
                path="/nft/:token_id/:item_id/:contractAddress"
                element={<NFTDetails />}
              />
              <Route
                path="/collection/:contractAddress/:category"
                element={<CollectionDetails />}
              />
              <Route path="/nfts/:collectionParam" element={<Nfts />} />
              {/*<Route path='create-item' element={<CreateItem />} />
                <Route path='batch-create-item/:contractAddress' element={<BatchCreateItems />} />*/}
              <Route
                path="mint-tokens/:contractAddress"
                element={<MintTokens />}
              />
              {/*<Route path='batch-create-equipement' element={<BatchCreateEquipements />} />*/}
              {/*<Route path='batch-create-reducers' element={<BatchCreateReducer />} />
                <Route path='create-asset' element={<CreateAsset />} />
                <Route path='creator-dashboard' element={<CreatorDashboard />} />*/}
              <Route path="manage-apps" element={<ManageApp />} />
              <Route
                path="deploy-nft-contract"
                element={<DeployNFTContract />}
              />
              <Route
                path="manage-collection/:contractAddress/:marketplaceAddress"
                element={<ManageCollection />}
              />
              <Route
                path="/my-assets/:token_id/:contractAddress"
                element={<MyAssetDetails />}
              />
              <Route path="/how-it-works/:collection" element={<HowItWork />} />
              <Route path="my-assets/:collectionName" element={<MyAssets />} />
              <Route path="profile/:collectionParam" element={<UserProfile />} />
              <Route path="*" element={<Nfts />} />
              {/*
                <Route path='staking' element={<Staking />} />
                <Route path='pool-holder' element={<PoolHolder />} />
              */}
            </Routes>
          </Container>
        </main>
        <Footer />
      </>
    </Router>
  );
};

export default App;
