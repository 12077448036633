import React from 'react'
import { useState } from 'react';
import { WhitelistAbi } from '../artifacts/contracts-abis/Whitelist-abi';
import { ethers } from 'ethers';
import { Button } from 'react-bootstrap';

export default function WhitelistRoles() {

    const [whitelistContract, setWhitelistContract] = useState(null);
    const [userAddress, setUserAddress] = useState(null);


    const handleAddToWhitelist = async() =>{
        const provider = new ethers.providers.Web3Provider(window.onBoardProvider, 'any')
        const signer = provider.getSigner()

        const contract = new ethers.Contract(
            whitelistContract,
            WhitelistAbi,
            signer)


        //role a changer par 0xe799c73ff785ac053943f5d98452f7fa0bcf54da67826fc217d6094dec75c5ee
        contract.setWhitelisted(userAddress).then(tx => {
            console.log(tx)
        }).catch(err => {
            console.error(err);
        })
    }

    return (
        <div style={{ display: "flex" }}>
            <div >
                <div style={{ display: "grid" }}>
                    <label htmlFor="userToGrantInput">Whitelist contract address:</label>
                    <input type="text" id="userToGrantInput" value={whitelistContract} onChange={(e) => setWhitelistContract(e.target.value)} />
                </div>
                <div style={{ display: "grid" }}>
                    <label htmlFor="userToGrantInput">User to whitelist:</label>
                    <input type="text" id="userToGrantInput" value={userAddress} onChange={(e) => setUserAddress(e.target.value)} />
                </div>
                <Button onClick={handleAddToWhitelist}>Add to Whitelist</Button>
            </div>
        </div>

    )
}
